import React, { useState } from 'react';
import './Perfil.css';
import { ThemeProvider } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { MUItheme } from './MUItheme';
import { NormalInput } from './NormalInput.js';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { change_password } from '../../api';

export function changePassword() {

  const [formData, setFormData] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    const body = {
        email: formData.email, 
        currentPassword: formData.currentPassword, 
        newPassword: formData.newPassword
    };
    console.log(JSON.stringify(body)); // Log the body for debugging
    event.preventDefault();

    try {
        toast.success('Password changed successfully!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const response = await change_password(formData.email, formData.currentPassword, formData.newPassword);

        if (response.ok) {
            navigate('/perfil'); // Navigate to profile page on success
        } else {
            throw new Error('Failed to change password'); // Throw an error to be caught in the catch block
        }
    } catch (error) {
        console.error(error);
        toast.error(`Error: ${error.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
  };


  return (
    <>
      <ToastContainer />
      <div className='main-container'>
          <div className='olvidar-container'>
          <ThemeProvider theme={MUItheme}>
            <h3 className='message-forgot-password'>Cambiar Contraseña</h3>
            <h3 className='instructions-forgot-password'>{'Ingresa tu correo para cambiar tu contraseña'}</h3>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
            <NormalInput text={'Correo electrónico'} onInputChange={handleInputChange} value={'email'}/>
            <HiddenInput text={'Contraseña Actual'} onInputChange={handleInputChange} value={'currentPassword'}/>
            <HiddenInput text={'Contraseña Nueva'} onInputChange={handleInputChange} value={'newPassword'}/>
            <SubmitButton handleLogin={handleLogin} buttonText={'Enviar'}/>
          </ThemeProvider>
    </div>
      </div>
    </>
  )
  
}

export default changePassword;