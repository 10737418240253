import React from 'react';
import Alert from '@mui/material/Alert';

export function EditarContraSuccessAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', top: '30%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
      <Alert variant="filled" severity="info">
      ¡Tu contraseña ha sido cambiada con exito!
      </Alert>
    </div>
  );
}

export function EditarContraErrorAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', top: '30%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}
