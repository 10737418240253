import React, { useEffect, useState, useRef} from 'react';

import imagen_perfil from "./imagenes/perfil_logo.png"
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';

//import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { get_communities, get_user_communities, get_profile,
  get_community_publications, delete_publication, like_publication,
  edit_publication, create_comment, delete_comment, get_comments, repost_publication, 
  search_community, get_feed_publications} from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Icons
import {AiFillHeart} from 'react-icons/ai';
import {MdDone} from 'react-icons/md';
import {BiPencil} from 'react-icons/bi';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {AiOutlineSend} from 'react-icons/ai';

import { DeletePublicationAlert, ErrorDeletePublicationAlert, 
  EditPublicationAlert, ErrorEditPublicationAlert, CreateCommentAlert, ErrorCreateCommentAlert,
DeleteCommentAlert, ErrorDeleteCommentAlert, RepostAlert, ErrorRepostAlert} from './Alerts';


function Feed() {

  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [likedCommunities, setLikedCommunities] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [posts, setPosts] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [publicationImages, setImages] = useState({});

  const [communities, setCommunities] = useState([]);
  const [searchedCommunities, setSearchedCommunities] = useState([]);


  const [isEditFormVisible, setIsEditFormVisible] = useState({});
  const {register, handleSubmit } = useForm();

  const [commentsDictionary, setCommentsDictionary] = useState({});
  const [isCommentsVisible, setIsCommentsVisible] = useState({});
  const [createComment, setCreateComment] = useState('');

  const [completedForm,setCompletedForm] = useState(true);

  const [paginaActual, setPaginaActual] = useState(1);
  const [puedeCargar, setPuedeCargar] = useState(true);

  const [isRepostFormVisible, setIsRepostFormVisible] = useState({});
  const [selectedOption, setSelectedOption] = useState('');

  const [baseUrl, setBaseUrl] = useState('');

  const [loadingFeed, setLoadingFeed] = useState(false);

  const inputRef = useRef(null);

  const [publicationDates, setDates] = useState([]);
  const [canFetch, setCanFetch] = useState(true);


  const fetchCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_communities(token);
      const responseData = await response.data;
      setCommunities(responseData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setCompletedForm(false);
        toast.error('Completa tu formulario para acceder al feed de publicaciones, seras redireccionada a tu perfil', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(function() {
          console.log('Pasaron 3 segundos');
          navigate("/perfil");
        }, 6000);
        
      }
      console.error('Error fetching data:', error);
    }
  };

  const fetchYourCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_user_communities(token);
      const responseData = await response.data;
      setLikedCommunities(responseData.otherCommunities);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_profile(jwtToken);
      const responseData = await response.json();
      setCurrentUser(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchFeedPublications = async (pagina) => {
    try {
      setLoadingFeed(true); // Set loading to true before making the request
      await fetchCurrentUser();
      const token = Cookies.get('jwtToken');
      const allPosts = [];
      const allCargos = [];
      const allImages = {};
      const allDates = [];
      const response = await get_feed_publications(token, "createdAt", pagina);
      const responseData = await response.json();
      console.log(responseData.posts);
      allPosts.push(...responseData.posts);
      allCargos.push(...responseData.cargosArray);
      allDates.push(...responseData.dateTimeArray);
      await Promise.all(responseData.postMultimediaArray.map(async (image, index) =>{
        if (image.length!==0){
          if (responseData.posts[index].id in allImages) {
            allImages[responseData.posts[index].id].push(image[0]);
          } else {
            allImages[responseData.posts[index].id] = [image[0]];
          }
        }
      }));
      setPosts(prevPosts=>[ ...prevPosts, ...allPosts]);
      setCargos(prevCargos => [...prevCargos,...allCargos]);
      setImages(prevImages => ({
        ...prevImages,
        ...allImages
      }));
      setDates(prevDates =>[...prevDates, ...allDates]);     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingFeed(false);
    }
  };

  const handleLikePost = async (postId) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await like_publication(token, postId);
      const nuevosPosts = [...posts];
      const postIndex = nuevosPosts.findIndex((post) => post.id === postId);
      if (postIndex !== -1) {
        if (nuevosPosts[postIndex].liked === true){
          nuevosPosts[postIndex].liked = false;
        }
        else{
          nuevosPosts[postIndex].liked = true;
        }
      setPosts(nuevosPosts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };  

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
      setBaseUrl(window.location.origin);
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    fetchCommunities();
    fetchYourCommunities();
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    const fetchData = async () =>{
      

      if (paginaActual > 0){
        await fetchFeedPublications(paginaActual);
      setCanFetch(true);
      }
      
    }
    setLoadingFeed(true);
    fetchData();
    setLoadingFeed(false);
  }, [paginaActual]);
 
  const editPublicationSubmit = async (data, publicationId) => {
    try {
      const content = data[`publication-${publicationId}`];
      const token = Cookies.get('jwtToken');
      let response = await edit_publication(token, publicationId, content);
      if (response.status === 200) {
        const nuevosPosts = [...posts];
        const postIndex = nuevosPosts.findIndex((post) => post.id === publicationId);
        if (postIndex !== -1) {
          nuevosPosts[postIndex].content = content;
        }
        setPosts(nuevosPosts);
        handleShowPublicationEditForm(publicationId);
        setEditPublicationError(false);
        setEditPublicationSuccess(true);
        setTimeout(() => {
          setEditPublicationSuccess(false);
        }, 2000);
      }
    } catch (error) {
      setEditPublicationError(true);
      setEditPublicationError(false);
      setTimeout(() => {
        setEditPublicationError(false);
      }, 2000);
    }
  };

  const handleShowPublicationEditForm = async (publicationId) => {
    if (isEditFormVisible[publicationId]){
      toggleEditPublicationVisibility(publicationId);
    } else {
      toggleEditPublicationVisibility(publicationId);
    }
  };

  const toggleEditPublicationVisibility = (publicationId) => {
    setIsEditFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const handleDeletePublication = async (publicationId, index) => {
    const confirmed = window.confirm("¿Estas segura de que quieres eliminar la publicación?");
    if (confirmed) {
      try {
        const token = Cookies.get('jwtToken');
        let response = await delete_publication(token, publicationId);
        if (response.status === 200) {
          const nuevosPosts = posts.filter((post) => post.id !== publicationId);
          const nuevosCargos = cargos.splice(index,1);
          const nuevasImages =  { ...publicationImages };
          if (nuevasImages.hasOwnProperty(publicationId)) {
            delete updatedImages[publicationId];
          }
          setPosts(nuevosPosts);
          setCargos(nuevosCargos);
          setImages(nuevasImages);
          setDeletePublicationError(false);
          setDeletePublicationSuccess(true);
          setTimeout(() => {
            setDeletePublicationSuccess(false);
          }, 2000);
        }
      } catch (error) {
        setDeletePublicationError(true);
        setDeletePublicationSuccess(false);
        setTimeout(() => {
          setDeletePublicationError(false);
        }, 2000);
      }
    }
  };

  const fetchComments = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const commentsDict = {};
  
      const promises = posts.map(async (post) => {
        const response = await get_comments(token, post.id);
        const commentsData = await response.data.comments;
  
        // Initialize the comments array for this post ID
        commentsDict[post.id] = [];
  
        commentsData.forEach(comment => {
          // Push the complete comment object into the array
          commentsDict[post.id].push(comment);
        });
      });
      await Promise.all(promises);
      setCommentsDictionary(commentsDict);  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchComments();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  
  }, []);
  
  const handleCreateComment = async (e, postId) => {
    e.preventDefault();
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await create_comment(token, postId, createComment);
      if (response.status === 201) {
        await fetchComments();
        setCreateCommentError(false);
        setCreateCommentSuccess(true);
        setTimeout(() => {
          setCreateCommentSuccess(false);
        }, 2000);
        isCommentsVisible[postId] = true;
      } else {
        setCreateCommentError(true);
        setCreateCommentSuccess(false);
        setTimeout(() => {
          setCreateCommentError(false);
        }, 2000);  
      }
      await fetchComments();
    } catch (error) {
      setCreateCommentError(true);
      setCreateCommentSuccess(false);
      setTimeout(() => {
        setCreateCommentError(false);
      }, 2000);  
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await delete_comment(token, commentId);
      if (response.status === 204) {
        setDeleteCommentError(false);
        setDeleteCommentSuccess(true);
        setTimeout(() => {
          setDeleteCommentSuccess(false);
        }, 2000);
        await fetchComments();
      } else {
        setDeleteCommentError(true);
        setDeleteCommentSuccess(false);
        setTimeout(() => {
          setDeleteCommentError(false);
        }, 2000);  
      }
    } catch (error) {
      setDeleteCommentError(true);
      setDeleteCommentSuccess(false);
      setTimeout(() => {
        setDeleteCommentError(false);
      }, 2000);  
    }
  };

  const handleCollapseComments = async (publicationId) => {
    await fetchComments();
    if (isCommentsVisible[publicationId]){
      toggleCommentsVisibility(publicationId);
    } else {
      toggleCommentsVisibility(publicationId);
    }
  };

  const toggleCommentsVisibility = (publicationId) => {
    setIsCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };
    
  const [anchorElPen, setAnchorElPen] = useState(null);
  const [menuStatesEdit, setMenuStatesEdit] = useState({});

  const handleClickPen = (event, postId) => {
    setAnchorElPen(event.currentTarget);
    setMenuStatesEdit((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId], // Cambiar el estado al contrario del valor actual
    }));
  };

  const handleClosePen = () => {
    setAnchorElPen(null);
    setMenuStatesEdit({});
  };
  
  useEffect(() => {
  let scrollElement;
  let canFetch = true; // Variable para controlar si se puede realizar la solicitud

  const delayedGetScrollElement = () => {
    scrollElement = document.getElementById('scrollElement');
    if (scrollElement) {
      const handleScroll = async () => {
        const scrollHeight = scrollElement.scrollHeight;
        const visibleHeight = scrollElement.clientHeight;
        const scrollTop = scrollElement.scrollTop;

        if (scrollHeight - scrollTop <= visibleHeight + 10 && canFetch) {
          setPuedeCargar(false);

          // Actualiza la variable canFetch para evitar solicitudes múltiples
          canFetch = false;
          setPaginaActual((pagina)=> pagina+1);
          await fetchYourCommunities();
          await fetchFeedPublications()
            .then(() => {
              // Habilita la posibilidad de realizar otra solicitud
              canFetch = true;
              setPuedeCargar(true); // Asegúrate de que se pueda cargar nuevamente
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
              // Habilita la posibilidad de realizar otra solicitud incluso en caso de error
              canFetch = true;
              setPuedeCargar(true);
            });
        }
      };
      scrollElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  };
  const delay = 100; // Ajusta el tiempo de retraso según sea necesario
  const timeoutId = setTimeout(() => {
    delayedGetScrollElement();
  }, delay);

  // Limpia el timeout si el componente se desmonta antes de que se ejecute
  return () => {
    clearTimeout(timeoutId);
  };
}, []);

const handleSelectChange = (e) => {
  setSelectedOption(e.target.value);
};

const repostPublicationSubmit = async (e, postId) => {
  e.preventDefault();
  const content = e.target[`repost-publication-${postId}`].value;
  const selectedCommunityId = e.target.querySelector('select').value;
  try {
    const token = Cookies.get('jwtToken');
    let response = await repost_publication(token, postId, selectedCommunityId, content);
    if (response.status === 201) {
      // Successful repost
      setRepostError(false);
      setRepostSuccess(true);
      await fetchFeedPublications();
      setTimeout(() => {
        setRepostSuccess(false);
      }, 2000);
    }
  } catch (error) {
    // Error during repost
    setRepostError(true);
    setRepostSuccess(false);
    setTimeout(() => {
      setRepostSuccess(false);
    }, 2000);
  }
};

const handleShowPublicationRepostForm = async (publicationId) => {
  handleClosePen();
  if (isRepostFormVisible[publicationId]){
    toggleRepostPublicationVisibility(publicationId);
  } else {
    toggleRepostPublicationVisibility(publicationId);
  }
};

const toggleRepostPublicationVisibility = (publicationId) => {
  setIsRepostFormVisible((prevVisibility) => ({
    ...prevVisibility,
    [publicationId]: !prevVisibility[publicationId], // Toggle visibility
  }));
};

const [deletePublicationSuccess, setDeletePublicationSuccess] = useState(false);
const [publicationDeleteError, setDeletePublicationError] = useState(false);

const [editPublicationSuccess, setEditPublicationSuccess] = useState(false);
const [editPublicationError, setEditPublicationError] = useState(false);

const [createCommentSuccess, setCreateCommentSuccess] = useState(false);
const [createCommentError, setCreateCommentError] = useState(false);

const [deleteCommentSuccess, setDeleteCommentSuccess] = useState(false);
const [commentDeleteError, setDeleteCommentError] = useState(false);

const [repostSuccess, setRepostSuccess] = useState(false);
const [repostError, setRepostError] = useState(false);
 
const handleSearch = async () => {
  const inputValue = inputRef.current.value;
  const bodyRequest = {nombre: inputValue};
  const token = Cookies.get('jwtToken');
  const response = await search_community(token, bodyRequest);
  setSearchedCommunities(response);
  console.log(response);
}

return (
  <><ToastContainer limit={1} />
    {completedForm ? (
    <div>
    {isLoggedIn && (
      <>
      <div className='scroll' >
      <div className="desktop_feed"  >
      <div className="div" >
        <div className="overlap-group" id='scrollElement'>

        {loadingFeed && 
        <div className="rectangulo_almedio">
          <div className='contenedor-mayor'>
            <div className="flex-container"> </div>
            <div className="spinner"></div>
            <div className="flex-container"> </div>
          </div>

        </div>}
        {deletePublicationSuccess && (
          <DeletePublicationAlert />
        )}
        {publicationDeleteError && (
          <ErrorDeletePublicationAlert />
        )}
        {editPublicationSuccess && (
          <EditPublicationAlert />
        )}
        {editPublicationError && (
          <ErrorEditPublicationAlert />
        )}
        {createCommentSuccess && (
          <CreateCommentAlert />
        )}
        {createCommentError && (
          <ErrorCreateCommentAlert />
        )}
        {deleteCommentSuccess && (
          <DeleteCommentAlert />
        )}
        {commentDeleteError && (
          <ErrorDeleteCommentAlert />
        )}
        {repostSuccess && (
          <RepostAlert />
        )}
        {repostError && (
          <ErrorRepostAlert />
        )}
        {posts.map((post, index) => (
          <div key={index}>
            <div className="rectangulo_almedio">
              <div className='contenedor-mayor'>
                  <div className="flex-container"> 
                  <img className="mini-sphere" alt="Imagen" src={imagen_perfil} />  
                      <div className='container-usuaria'> 
                        <h3 className="nombre-usuaria">{cargos[index]}</h3>
                        <p className="fecha">{publicationDates[index][0]} - {publicationDates[index][1]}</p>
                      </div>
                      {currentUser.id == post.userId ?(
                        <div className='col publication_edit_option'>
                          <BiPencil id={`menu-edit-${post.id}`} aria-controls={`menu-edit-${post.id}`} className='publication_edit_button_1' size={20} onClick={(event)=>handleClickPen(event,post.id)}/>
                                    <Menu
                                    id={`menu-edit-${post.id}`}
                                    anchorEl={anchorElPen}
                                    open={menuStatesEdit[post.id] || false}
                                    onClose={handleClosePen}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <MenuItem onClick={() => handleShowPublicationEditForm(post.id)}>Editar Publicación</MenuItem>
                                    <MenuItem onClick={() => handleDeletePublication(post.id, index)}>Eliminar Publicación</MenuItem>
                                    
                                  </Menu>
                        </div>
                      ):(
                        <></>
                      )}
                  </div>
                  {/*post.foto != "" && <img className="foto" alt="Imagen" src={foto} /> {cargos[index]}*/ }
                  <div>
                    <div className=''>
                      {!isEditFormVisible[post.id] && (
                        <div>
                          <h1 className='texto-feed'>{post.content}
                          {post.is_repost &&(
                            <a className="feed_repost_link" href={`${baseUrl}/publicacion/${post.repostedPost.originalPostId}`}>Ver publicación</a>
                          )}
                          </h1>
                          {publicationImages[post.id]?.map((image) => (
                            <img src={image} alt="" className='feed_publication_image'/>
                          ))}
                        </div>
                      )}
                      {isEditFormVisible[post.id] && (
                        <form
                          className='edit_publication_form'
                          onSubmit={(e) => handleSubmit((data) => editPublicationSubmit(data, post.id))(e)}
                        >
                          <div className='row'>
                            <div className='col'>
                              <textarea
                                className='publication_edit'
                                {...register(`publication-${post.id}`)}
                                placeholder='Publicación'
                                style={{
                                  width: '50vw',
                                  height: '7vh',
                                  padding: '10px',
                                  border: '1px solid #ccc',
                                  borderRadius: '10px',
                                  boxSizing: 'border-box',
                                  fontSize: '16px',
                                  marginLeft: '4vw',
                                  resize: 'none',
                                  backgroundColor: '#FAFAFA',
                                  transition: 'border-color 0.3s ease',
                                }}
                                defaultValue={post.content}
                              />
                            </div>
                            <div className='col buttons_row_edit'>
                              <button type='submit' className='edit_community_button'>
                                <MdDone size={14}/>
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                      {isRepostFormVisible[post.id] && (
                        <div className='repost_div'>
                          <img
                            src='/images/cancel_icon.svg'
                            className='publication_repost_cancel_icon'
                            onClick={() => handleShowPublicationRepostForm(post.id)} 
                          />
                          <h5 className='repost_title'>Reenvía esta publicación<img src="/images/Vector_send.svg" className='publication_options_icon_repost' /></h5>
                          <form
                            className='repost_publication_form'
                            onSubmit={(e) => repostPublicationSubmit(e, post.id)}
                          >
                            <div className='row'>
                              <label>
                                <h6 className='repost_community_title'>Comunidad:</h6>
                                <select value={selectedOption} onChange={handleSelectChange} className='repost_dropdown'>
                                  <option value="">Select...</option>
                                  {likedCommunities.map((community) => (
                                    <option key={community.id} value={community.id}>
                                      {community.name}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            
                              <div className='col'>
                                <textarea
                                  className='publication_repost_textarea'
                                  {...register(`repost-publication-${post.id}`)}
                                  placeholder='Publicación'
                                  style={{ height: '8vh', width: '22vw', maxWidth: '22vw', minWidth: '22vw', borderRadius: '10px', margin: 'auto' , maxHeight: '22vh'}}
                                  defaultValue={`Miren este post!`}
                                />
                              </div>
                              <div className='col buttons_row_edit'>
                                <button type='submit'className="repost_button"><AiOutlineSend size={18}/></button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="linea_gris" />
                  <div className="flex-container"> 
                    
                    {post.liked &&(
                      <div className='col publication_like_column' onClick={() => handleLikePost(post.id)}>
                        <h4 className='liked_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='liked_publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                      </div>
                    )}

                    {!post.liked &&(
                      <div className='col publication_like_column' onClick={() => handleLikePost(post.id)}>
                        <h4 className='publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                      </div>
                    )}
                    <button className="button_comentar" onClick={() => handleCollapseComments(post.id)}>Comentarios</button>
                    
                    <div className='col publication_send_col' onClick={() => handleShowPublicationRepostForm(post.id)}>
                      <h4 className='publication_options_text2'><img src="/images/Vector_send.svg" className='publication_options_icon' /> Reenviar</h4>
                    </div>
                  
                  </div> 
 
                </div> 

              {isCommentsVisible[post.id] && (
                <div className='comments_div'>
                  {commentsDictionary[post.id] && commentsDictionary[post.id].map((comment) => (
                    <div className='comment_div'>
                      <div className='col publication_delete_option'>
                        <img src='/images/cancel_icon.svg' className='publication_comment_cancel_icon' onClick={() => handleDeleteComment(comment.id)}/>
                      </div>
                      <h4 className='comment_text'>{comment.content}</h4>
                    </div>
                  ))}
                </div>
              )}
              <div className='flex-container'>
                <div className='row'>
                  <div>
                    <img className="mini-sphere2" alt="Imagen" src={imagen_perfil} />  
                  </div>
                </div>
                <div className='row' key={post.id}>
                  <form className='container-usuaria2' onSubmit={(e) => handleCreateComment(e, post.id)}>
                    <input
                      className='input-escribealgo'
                      placeholder='Escribe algo...'
                      onChange={(e) => setCreateComment(e.target.value)}
                    />
                  </form>
                </div>

               </div>
            </div>             
          </div>
        ))}

        <div className="rectangulo-arriba">
          <div className="flex-container">
            <img className="pequena-sphere" alt="Imagen" src={imagen_perfil} />   
            <p className='comunidades'>Comunidades</p> 
            <button className='tres-puntos'>...</button>
          </div>

          <div className='linea-gris-corta'></div>
          <input         
            ref={inputRef}
            type="text"
            onKeyDown={handleSearch} 
            className='input-buscargrupos'
            placeholder='Buscar grupos' 
          />
          <div className='searched_communities_scrollable'>
            {searchedCommunities.length > 0 && (
              searchedCommunities.map((community) => (
                <>
                  <div className='linea-gris-corta'></div>
                  <div className="flex-container"> 
                    <div className="otrasphere">
                      <div className='pequeno-circulo-rosa'></div>
                    </div>
                    <div className='container-comunidad'> 
                      <p className="nombre-comunidad">{community.name}</p>
                      <p className="ultimo-mensaje">Último mensaje del grupo</p>
                    </div>
                    <p className='dosoctubre'>2 Oct</p>
                  </div>
                </>
              ))
            )}
          </div>
        </div>

      </div>

      </div>
    </div>

    </div>
    </>
    )}
    </div>):null};
  <div>  
  </div></>
);
}



export default Feed;