// Footer.js
import React, { useEffect, useState, useRef} from 'react';
import './styles.css';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import { get_communities, get_user_communities, get_users, get_profile,
  get_community_publications, delete_publication, like_publication,
  join_community, edit_publication, create_comment, delete_comment, get_comments,
  leave_community, create_publication, get_community, repost_publication} from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import { PublicationAlert, ErrorPublicationAlert } from './Alerts';
import { DeletePublicationAlert, ErrorDeletePublicationAlert, 
  EditPublicationAlert, ErrorEditPublicationAlert, CreateCommentAlert, ErrorCreateCommentAlert,
        DeleteCommentAlert, ErrorDeleteCommentAlert, JoinComunityAlert, ErrorJoinComunityAlert, RepostAlert, ErrorRepostAlert} from './Alerts';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import imagen_perfil from "./imagenes/perfil_logo.png"

import { useParams } from 'react-router-dom';

import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';

import {AiOutlineSend} from 'react-icons/ai';
import {AiFillHeart} from 'react-icons/ai';
import {MdDone} from 'react-icons/md';
import {BiPencil} from 'react-icons/bi';



function Comunidad() {
  const { communityId } = useParams();
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMember, setIsMember] = useState(false);


  const [community, setCommunity] = useState([]);
  const [communities, setCommunities] = useState([]);


  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [activeInfo, setActiveInfo] = useState(1);

  
  const [content, setContent] = useState('');

  const [selectedImage, setSelectedImage] = useState(null);

  const [currentUser, setCurrentUser] = useState({});
  const [posts, setPosts] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [publicationImages, setImages] = useState({});

  const [isEditFormVisible, setIsEditFormVisible] = useState({});
  const {register, handleSubmit } = useForm();

  const [commentsDictionary, setCommentsDictionary] = useState({});
  const [isCommentsVisible, setIsCommentsVisible] = useState({});
  const [createComment, setCreateComment] = useState('');

  const [joinComunitySuccess, setJoinComunitySuccess] = useState(false);
  const [joinComunityError, setJoinComunityError] = useState(false);
  const [loadingComunidad, setLoadingComunidad] = useState(false);
  const [loadingComunidadesRelacionadas, setLoadingComunidadesRelacionadas] = useState(false);

  const [completedForm,setCompletedForm] = useState(true);
  const [paginaActual, setPaginaActual] = useState(1);
  const [canFetch, setCanFetch] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  const initialRender = useRef(true);

  const [isRepostFormVisible, setIsRepostFormVisible] = useState({});
  const [selectedOption, setSelectedOption] = useState('');

  const [baseUrl, setBaseUrl] = useState('');
  const [likedCommunities, setLikedCommunities] = useState([]);

  const [publicationDates, setDates] = useState([]);



  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const fetchYourCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_user_communities(token);
      const responseData = await response.data;
      setLikedCommunities(responseData.otherCommunities);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleInfoChange = (infoNumber) => {
    setActiveInfo(infoNumber);
  };

  const fetchCommunity = async (communityId) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_community(token, communityId);
      const responseData = await response.data;
      setCommunity(responseData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setCompletedForm(false);
        toast.error('Completa tu formulario para acceder a la comunidad, seras redireccionada a tu perfil', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(function() {
          console.log('Pasaron 3 segundos');
          navigate("/perfil");
        }, 6000);
        
      }
      console.error('Error fetching data:', error);
    }
    
  };

  const fetchCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_communities(token);
      const responseData = await response.data;
      const elementsPerSlide = 4;
      const numberOfSlides = Math.ceil(responseData.length / elementsPerSlide);
      // Split the array into chunks to represent each slide
      const slideChunks = [];
      for (let i = 0; i < numberOfSlides; i++) {
        slideChunks.push(responseData.slice(i * elementsPerSlide, (i + 1) * elementsPerSlide));
      }
      setCommunities(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleJoinClick = async (community_id) => {
    try {
      const token = Cookies.get('jwtToken');
      let response = await join_community(token, community_id);
      if (response.status === 200) {
        try {
          // Make a GET request to your API endpoint
          setJoinComunitySuccess(true);
          setTimeout(() => {
            setJoinComunitySuccess(false);
          }, 2000);
          const response = await get_communities(token);
          const responseData = await response.data;
          setCommunities(responseData.reverse());
        } catch (error) {
          setJoinComunityError(true);
          setTimeout(() => {
            setJoinComunityError(false);
          }, 2000);
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      alert('Error al seguir la comunidad');
      console.error(error);
    }
  };

  const fetchIsMember = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_user_communities(token);
      const responseData = await response.data;
      for (let own_community of responseData.otherCommunities){
        if (own_community.id == community.id){
          setIsMember(true);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await create_publication(token, community.id, content, selectedImage);
      setPublicationError(false);
      setPublicationSuccess(true);
      setPosts([]);
      setCargos([]);
      setImages({});
      setPaginaActual(prevCount => prevCount === 1 ? prevCount : 1);
      setForceUpdate(prevForceUpdate => !prevForceUpdate);
      setTimeout(() => {
        setPublicationSuccess(false);
      }, 2000);
    } catch (error) {
      console.error('Error fetching data:', error);
      setPublicationError(true);
      setPublicationSuccess(false);
      setTimeout(() => {
        setPublicationError(false);
      }, 2000);
    }
  };

 //Login
  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
      setBaseUrl(window.location.origin);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_profile(jwtToken);
      const responseData = await response.json();
      console.log(responseData);
      setCurrentUser(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };
 
  //CurrentUser
  useEffect(() => {
    const fetchData = async () => {
    try{
      await fetchCurrentUser();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   }
    fetchData();
  }, []);

  //Comunidad y comunidades
  useEffect(() => {
    // Fetch data when the component mounts
    setLoadingComunidad(true);
    setLoadingComunidadesRelacionadas(true);
    const fetchData = async () => {
      try {
        //console.log(communityId);
        await fetchCommunity(communityId);
        setLoadingComunidad(false);
        await fetchCommunities();
        await fetchYourCommunities();
        setLoadingComunidadesRelacionadas(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    
  }, [communityId]);


//Is member
  useEffect(() => {
    // Check if the user is a member when `community` is updated
    if (community) {
      fetchIsMember();
    }
  }, [community]);

  const handleJoinCommunity = async (community_id) => {
    try {
      const token = Cookies.get('jwtToken');
      let response = await join_community(token, community_id);
      if (response.status === 200) {
        try {
          // Make a GET request to your API endpoint
          setJoinComunitySuccess(true);
          setTimeout(() => {
            setJoinComunitySuccess(false);
          }, 2000);
          const response = await get_communities(token);
          const responseData = await response.data;
          setCommunities(responseData);
          fetchYourCommunities();
          await fetchIsMember();
        } catch (error) {
          setJoinComunityError(true);
          setTimeout(() => {
            setJoinComunityError(false);
          }, 2000);
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      alert('Error al seguir la comunidad');
      console.error(error);
    }
  };

  const [publicationSuccess, setPublicationSuccess] = useState(false);
  const [publicationError, setPublicationError] = useState(false);
  const [deletePublicationSuccess, setDeletePublicationSuccess] = useState(false);
  const [publicationDeleteError, setDeletePublicationError] = useState(false);

  const [editPublicationSuccess, setEditPublicationSuccess] = useState(false);
  const [editPublicationError, setEditPublicationError] = useState(false);

  const [createCommentSuccess, setCreateCommentSuccess] = useState(false);
  const [createCommentError, setCreateCommentError] = useState(false);

  const [deleteCommentSuccess, setDeleteCommentSuccess] = useState(false);
  const [commentDeleteError, setDeleteCommentError] = useState(false);

  const [repostSuccess, setRepostSuccess] = useState(false);
  const [repostError, setRepostError] = useState(false);
  
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed:1000,
    nextArrow: <button class="slick-next"></button>,
    prevArrow: <button class="slick-prev"></button>
  };

  const handleLikePost = async (postId) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await like_publication(token, postId);
      const nuevosPosts = [...posts];
      const postIndex = nuevosPosts.findIndex((post) => post.id === postId);
      if (postIndex !== -1) {
        if (nuevosPosts[postIndex].liked === true){
          nuevosPosts[postIndex].liked = false;
        }
        else{
          nuevosPosts[postIndex].liked = true;
        }
      setPosts(nuevosPosts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };  
  
  const [puedeCargar, setPuedeCargar] = useState(true);

  const fetchFeedPublications = async (pagina) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const allPosts = [];
      const allCargos = [];
      const allImages = {};
      const allDates = [];
      const response = await get_community_publications(token, communityId, "createdAt", pagina);
      const responseData = await response.json();
      allPosts.push(...responseData.posts);
      allCargos.push(...responseData.cargosArray);
      allDates.push(...responseData.dateTimeArray);
      await Promise.all(responseData.postMultimediaArray.map(async (image, index) =>{
        if (image.length!==0){
          if (responseData.posts[index].id in allImages) {
            allImages[responseData.posts[index].id].push(image[0]);
          } else {
            allImages[responseData.posts[index].id] = [image[0]];
          }
        }
      }));
      
      setPosts(prevPosts=>[ ...prevPosts, ...allPosts]);
      setCargos(prevCargos => [...prevCargos,...allCargos]);
      setImages(prevImages => ({
        ...prevImages,
        ...allImages
      }));
      setDates(prevDates =>[...prevDates, ...allDates]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const editPublicationSubmit = async (data, publicationId) => {
    try {
      const content = data[`publication-${publicationId}`];
      const token = Cookies.get('jwtToken');
      let response = await edit_publication(token, publicationId, content);
      if (response.status === 200) {
        const nuevosPosts = [...posts];
        const postIndex = nuevosPosts.findIndex((post) => post.id === publicationId);
        if (postIndex !== -1) {
          nuevosPosts[postIndex].content = content;
        }
        setPosts(nuevosPosts);
        handleShowPublicationEditForm(publicationId);
        setEditPublicationError(false);
        setEditPublicationSuccess(true);
        setTimeout(() => {
          setEditPublicationSuccess(false);
        }, 2000);
      }
    } catch (error) {
      setEditPublicationError(true);
      setEditPublicationError(false);
      setTimeout(() => {
        setEditPublicationError(false);
      }, 2000);
    }
  };

  const handleShowPublicationEditForm = async (publicationId) => {
    if (isEditFormVisible[publicationId]){
      toggleEditPublicationVisibility(publicationId);
    } else {
      toggleEditPublicationVisibility(publicationId);
    }
  };

  const toggleEditPublicationVisibility = (publicationId) => {
    setIsEditFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const handleDeletePublication = async (publicationId, index) => {
    const confirmed = window.confirm("¿Estas segura de que quieres eliminar la publicación?");
    if (confirmed) {
      try {
        const token = Cookies.get('jwtToken');
        let response = await delete_publication(token, publicationId);
        if (response.status === 200) {
          const nuevosPosts = posts.filter((post) => post.id !== publicationId);
          const nuevosCargos = cargos.splice(index,1);
          const nuevasImages =  { ...publicationImages };
          if (nuevasImages.hasOwnProperty(publicationId)) {
            delete updatedImages[publicationId];
          }
          setPosts(nuevosPosts);
          setCargos(nuevosCargos);
          setImages(nuevasImages);
          setDeletePublicationError(false);
          setDeletePublicationSuccess(true);
          setTimeout(() => {
            setDeletePublicationSuccess(false);
          }, 2000);
        }
      } catch (error) {
        setDeletePublicationError(true);
        setDeletePublicationSuccess(false);
        setTimeout(() => {
          setDeletePublicationError(false);
        }, 2000);
      }
    }
  };

  const fetchComments = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const commentsDict = {};

      
      if (posts.length!==0){
      const promises = posts.map(async (post) => {
        const response = await get_comments(token, post.id);
        const commentsData = await response.data.comments;
  
        // Initialize the comments array for this post ID
        commentsDict[post.id] = [];
  
        commentsData.forEach(comment => {
          // Push the complete comment object into the array
          commentsDict[post.id].push(comment);
        });
      });
      await Promise.all(promises);
    }
    
      setCommentsDictionary(commentsDict);  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  //Comentarios, cambia cada vez que cambia posts
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchComments();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the async function immediately inside useEffect
  
  }, [posts]);

  const handleCreateComment = async (e, postId) => {
    e.preventDefault();
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await create_comment(token, postId, createComment);
      if (response.status === 201) {
        await fetchComments();
        setCreateCommentError(false);
        setCreateCommentSuccess(true);
        setTimeout(() => {
          setCreateCommentSuccess(false);
        }, 2000);
        isCommentsVisible[postId] = true;
      } else {
        setCreateCommentError(true);
        setCreateCommentSuccess(false);
        setTimeout(() => {
          setCreateCommentError(false);
        }, 2000);  
      }
      await fetchComments();
    } catch (error) {
      setCreateCommentError(true);
      setCreateCommentSuccess(false);
      setTimeout(() => {
        setCreateCommentError(false);
      }, 2000);  
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await delete_comment(token, commentId);
      if (response.status === 204) {
        setDeleteCommentError(false);
        setDeleteCommentSuccess(true);
        setTimeout(() => {
          setDeleteCommentSuccess(false);
        }, 2000);
        await fetchComments();
      } else {
        setDeleteCommentError(true);
        setDeleteCommentSuccess(false);
        setTimeout(() => {
          setDeleteCommentError(false);
        }, 2000);  
      }
    } catch (error) {
      setDeleteCommentError(true);
      setDeleteCommentSuccess(false);
      setTimeout(() => {
        setDeleteCommentError(false);
      }, 2000);  
    }
  };

  const handleCollapseComments = async (publicationId) => {
    await fetchComments();
    if (isCommentsVisible[publicationId]){
      toggleCommentsVisibility(publicationId);
    } else {
      toggleCommentsVisibility(publicationId);
    }
  };

  const toggleCommentsVisibility = (publicationId) => {
    setIsCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };
//Publicaciones, cambia cada vez que se cambia la comunidad
  useEffect(() => {
    const fetchData = async () =>{
      
      if (community){
        if (paginaActual > 0){
      await fetchFeedPublications(paginaActual);
      setCanFetch(true);
        }
      }
    }
    fetchData();
  }, [paginaActual, forceUpdate]);

  //Scroll de 
  useEffect(() => {
    let scrollElement;
    let canFetch = true; // Variable para controlar si se puede realizar la solicitud
  
    const delayedGetScrollElement = () => {
      scrollElement = document.getElementById('scrollElement');
      if (scrollElement) {
        const handleScroll = async () => {
          const scrollHeight = scrollElement.scrollHeight;
          const visibleHeight = scrollElement.clientHeight;
          const scrollTop = scrollElement.scrollTop;
          console.log(scrollHeight);
          if (scrollHeight - scrollTop <= visibleHeight + 10 && canFetch) {
            console.log('Se llegó al final del scroll.');
            setPuedeCargar(false);
  
            // Actualiza la variable canFetch para evitar solicitudes múltiples
            canFetch = false;
            console.log(paginaActual);
            setPaginaActual((pagina)=> pagina+1);
            console.log(paginaActual);
            await fetchCommunities();
            await fetchFeedPublications()
              .then(() => {
                // Habilita la posibilidad de realizar otra solicitud
                canFetch = true;
                setPuedeCargar(true); // Asegúrate de que se pueda cargar nuevamente
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                // Habilita la posibilidad de realizar otra solicitud incluso en caso de error
                canFetch = true;
                setPuedeCargar(true);
              });
          }
        };
  
        scrollElement.addEventListener('scroll', handleScroll);
  
        return () => {
          scrollElement.removeEventListener('scroll', handleScroll);
        };
      }
    };
  
    const delay = 100; // Ajusta el tiempo de retraso según sea necesario
  
    const timeoutId = setTimeout(() => {
      delayedGetScrollElement();
    }, delay);
  
    // Limpia el timeout si el componente se desmonta antes de que se ejecute
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect (() =>{
    console.log("cambio pagina actual");
  },[paginaActual]
  );


  const handleSelectChange = (e) => {
    // Update the selectedOption state when the select value changes
    setSelectedOption(e.target.value);
  };
   
  const repostPublicationSubmit = async (e, postId) => {
    e.preventDefault();
  
    const content = e.target[`repost-publication-${postId}`].value;
    const selectedCommunityId = e.target.querySelector('select').value;
    try {
      const token = Cookies.get('jwtToken');
      let response = await repost_publication(token, postId, selectedCommunityId, content);
      console.log(response.status);
      if (response.status === 201) {
        setRepostError(false);
        setRepostSuccess(true);
        console.log("xd")
        
        setTimeout(() => {
          setRepostSuccess(false);
          setPosts([]);
          setCargos([]);
          setImages({});
          setPaginaActual(prevCount => prevCount === 1 ? prevCount : 1);
          setForceUpdate(prevForceUpdate => !prevForceUpdate);
        }, 2000);
      }
    } catch (error) {
      // Error during repost
      setRepostError(true);
      setRepostSuccess(false);
      setTimeout(() => {
        setRepostSuccess(false);
      }, 2000);
    }
  };
  
  

  const handleShowPublicationRepostForm = async (publicationId) => {
    if (isRepostFormVisible[publicationId]){
      toggleRepostPublicationVisibility(publicationId);
    } else {
      toggleRepostPublicationVisibility(publicationId);
    }
  };

  const toggleRepostPublicationVisibility = (publicationId) => {
    setIsRepostFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  return (
    <><ToastContainer limit={1} />
    {completedForm ? (
    <div className='div_profile'>
    {isLoggedIn && (
      <div className="desktop">

      <div className="div">
        {publicationSuccess &&( 
          <PublicationAlert />
        )}
        {publicationError &&(
          <ErrorPublicationAlert />
        )}
        {joinComunitySuccess &&(
          <JoinComunityAlert/>
        )}
        {joinComunityError &&(
          <ErrorJoinComunityAlert/>
        )}
        {deletePublicationSuccess && (
          <DeletePublicationAlert />
        )}
        {publicationDeleteError && (
          <ErrorDeletePublicationAlert />
        )}
        {editPublicationSuccess && (
          <EditPublicationAlert />
        )}
        {editPublicationError && (
          <ErrorEditPublicationAlert />
        )}
        {createCommentSuccess && (
          <CreateCommentAlert />
        )}
        {createCommentError && (
          <ErrorCreateCommentAlert />
        )}
        {deleteCommentSuccess && (
          <DeleteCommentAlert />
        )}
        {commentDeleteError && (
          <ErrorDeleteCommentAlert />
        )}
        {repostSuccess && (
          <RepostAlert />
        )}
        {repostError && (
          <ErrorRepostAlert />
        )}
        <div className="overlap-group" >
          <div className="rectangulo_principal">

            <div className="flex-container">
              <div className="group" ></div>
              <div className="rectangle-fucsia" ></div>
            </div>
            <div className="rectangulo_seccion">
              {loadingComunidad && <p className="nombre-community">Loading...</p>}
              {!loadingComunidad && <p className="nombre-community">{community.name}</p>}
              {community.descripcion != null && <p className="sobre-ti">{community.description}</p>}

              <p className="element-contactos">{community.followers} miembros</p>
              <div className="flex-container">
                {!isMember &&(
                  <button onClick={() => handleJoinCommunity(community.id)} className="button_rosa">Unirte al grupo</button>
                )}
                {isMember &&(
                  <button className="button_rosa">Miembro</button>
                )}
                <button className="button_collapse">
                  <img src='/images/Vector_up.svg'/>
                </button>
              </div>

              <p></p>
              <div className="linea_gris"></div>
            </div>
            
            <div className="rectangle_gris"></div>


            <div className="linea_rosa"></div>
            <div className="linea_rosa_corta"></div>
            <div className="elipse-fucsia"></div>

            <div className="community_rectangulo_seccion">
              <div className='enclosing_div_community'>
                <div className='row'>
                  <h1 className='other_communities_section_title'>Grupos relacionados</h1>
                </div>
                <div className='row'>
                <div className='community-other-communities-container'>
                  {loadingComunidadesRelacionadas && <p className="community_other_community_title">Loading...</p>}
                  <Slider {...settings}>
                  
                  {communities.map((mapElement, elementIndex) => ( 
                    <div className='community_other_community_div' key={elementIndex}>
                      <img src='/images/cancel_icon.svg' className='recommended_community_cancel_icon'/>
                      <h1 className='community_other_community_title'>{mapElement.name}</h1>
                      <h3 className='community_community_other_info'>15 mil miembros  -  10+
                      publicaciones al día {paginaActual} </h3>
                      <div className='row'>
                        <button className='community_join_badge' onClick={() => handleJoinClick(mapElement.id)}>Unirse</button>
                      </div>
                    </div>
                  ))}
                  </Slider>
                  </div>
                  </div>
                <div className="linea_gris"></div>
              </div>
            </div>

          <div className="rectangulo_seccion">          
            <div className="horizontal-bar">
              
              <button onClick={() => handleInfoChange(1)} className={activeInfo === 1 ? "button-activate" : "button-inactivate"}>
                Conversación
              </button>

              <button onClick={() => handleInfoChange(2)} className={activeInfo === 2 ? "button-activate" : "button-inactivate"}>
                Destacados
              </button>

              <button onClick={() => handleInfoChange(3)} className={activeInfo === 3 ? "button-activate" : "button-inactivate"}>
                Personas
              </button>

              <button onClick={() => handleInfoChange(4)} className={activeInfo === 4 ? "button-activate" : "button-inactivate"}>
                Eventos
              </button>

              <button onClick={() => handleInfoChange(5)} className={activeInfo === 5 ? "button-activate" : "button-inactivate"}>
                Multimedia
              </button>

              <div className="linea_gris_buttons"></div>
            </div>
          </div>
          {activeInfo === 1 && (
            <div className="info-container">
              {publicationSuccess &&( 
                <PublicationAlert />
              )}
              {publicationError &&(
                <ErrorPublicationAlert />
              )}
              {repostSuccess && (
                <PublicationAlert/>
              )}
              {repostError &&(
                <ErrorPublicationAlert />
              )}
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className='row publication_commenta'>
                    <div className='col'>
                      <img src={imagen_perfil} className='comment_profile_img'/>
                    </div>
                    <div className='col'>
                      <form className='box_comment_form' onSubmit={handleCreatePost}>
                        <input
                          className='community_publication_comment_input'
                          placeholder='Escribe algo...'
                          value={content} // Bind the input value to a state variable
                          onChange={handleContentChange} // Add an onChange event handler
                        />
              
                      <p></p>
                      
                      <label for="file-input" className="custom-file-input">Adjunta una foto </label>
                      <input type="file" id='file-input' onChange={handleImageChange} style={{ display: 'none'}} />
                      <button type='submit' className='community_create_publication_button'>
                         <AiOutlineSend size={18}/>
                      </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className='linea-gris-mediana'></div>
              </div>
            </div>
          )} 
          <div className="rectangulo_seccion">          
            <div className="horizontal-bar-comunidad">
              
              <button className={"button-abajo"}>
                Publicación Anónima
              </button>

              <button className={"button-abajo"}>
                Foto/Video
              </button>

              <button className={"button-abajo"}>
                Encuesta
              </button>

              <div className="linea_gris_buttons"></div>
            </div>
          </div>
          <div className='rectangulo_seccion community_publications_div' id='scrollElement' >
            {posts.map((post, index) => (
              <div className='community_publication_div'>
                <div className='publication_text_div'>
                  {currentUser.id == post.userId ?(
                    <div className='row community_publication_options_div'>
                        <div className='col publication_edit_option'>
                          <BiPencil className='community_publication_edit_button' size={21} onClick={() => handleShowPublicationEditForm(post.id)}/>
                        </div>
                    </div>
                  ):(
                    <></>
                  )}
                  <div className='row'>
                    <div className='col publication_text_div'>
                      <div className='row'>
                        <div className='col publication_user_image'>
                          <div className='comment_profile_circle'>
                            <img src={imagen_perfil} className='comment_profile_img_1'/>
                          </div>
                        </div>
                        <div className='col publication_user_text'>
                          <h4 className='community_cargo_publication_text'>{cargos[index]}</h4>
                          <h4 className='community_date_text'>{publicationDates[index][0]} - {publicationDates[index][1]}</h4>
                        </div>
                      </div>
                      {!isEditFormVisible[post.id] && (
                        <div className='community_publication_text_div'>
                          <h3 className='community_publication_text'>
                            {post.content}
                          
                          {post.is_repost &&(
                            <a className="repost_link" href={`${baseUrl}/publicacion/${post.repostedPost.originalPostId}`}>Ver publicación</a>
                          )}
                          </h3>
                          {publicationImages[post.id]?.map((image) => (
                          
                              <img src={image} alt="" className='community_publication_image' />
                       
                          ))}
                        </div>
                      )}
                      {isEditFormVisible[post.id] && (
                        <div className='row'>
                          <div className='col community_publication_edit_col'>
                            <form
                              className='edit_publication_form'
                              onSubmit={(e) => handleSubmit((data) => editPublicationSubmit(data, post.id))(e)}
                            >
                              <div className='row'>
                                <div className='col'>
                                  <textarea
                                    
                                    {...register(`publication-${post.id}`)}
                                    placeholder='Publicación'
                                    style={{ height: '7vh', width: '32vw', borderRadius: '10px' , marginLeft: '5vw'}}
                                    defaultValue={post.content}
                                  />
                                </div>
                                <div className='col'>
                                  <button type='submit' className='community_publication_edit_button'>
                                    <MdDone size={14}/>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className='col community_publication_delete_col'>
                            <img src='/images/cancel_icon.svg' className='community_publication_cancel_icon' onClick={() => handleDeletePublication(post.id)}/>
                          </div>
                        </div>
                      )}
                      {isRepostFormVisible[post.id] && (
                        <div className='community_repost_div'>
                          <img
                            src='/images/cancel_icon.svg'
                            className='community_repost_cancel_icon'
                            onClick={() => handleShowPublicationRepostForm(post.id)} 
                          />
                          <h5 className='community_repost_title'>Reenvía esta publicación<img src="/images/Vector_send.svg" className='community_options_icon_repost' /></h5>
                          <form
                            className='repost_publication_form'
                            onSubmit={(e) => repostPublicationSubmit(e, post.id)}
                          >
                            <div className='row'>
                              <label>
                                <h6 className='community_repost_subtitle'>Comunidad:</h6>
                                <select value={selectedOption} onChange={handleSelectChange} className='community_repost_dropdown'>
                                  <option value="">Select...</option>
                                  {likedCommunities.map((community) => (
                                    <option key={community.id} value={community.id}>
                                      {community.name}
                                    </option>
                                  ))}
                                </select>
                              </label>
                            </div>
                            <div className='row'>
                              <div className='col'>
                                <textarea
                                  className='community_repost_textarea'
                                  {...register(`repost-publication-${post.id}`)}
                                  placeholder='Publicación'
                                  style={{ height: '8vh', width: '22vw', maxWidth: '22vw', minWidth: '22vw', borderRadius: '10px', margin: 'auto' , maxHeight: '22vh'}}
                                  defaultValue={`Miren este post!`}
                                />
                              </div>
                              <div className='col community_buttons_row_repost'>
                                <button type='submit'className="button_rosa">Publicar</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row community_publication_options'>
                  {post.liked &&(
                    <div className='col community_publication_like_col' onClick={() => handleLikePost(post.id)}>
                      <h4 className='community_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='liked_publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                    </div>
                  )}
                  {!post.liked &&(
                    <div className='col community_publication_like_col' onClick={() => handleLikePost(post.id)}>
                      <h4 className='community_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                    </div>
                  )}
                  <button className="community_publication_options_button" onClick={() => handleCollapseComments(post.id)}>
                    <img src="/images/comment_logo.svg" className='publication_options_icon' alt='Comment Icon'/>
                    <span className='publication_options_text'>Comentarios</span>
                  </button>

                  <div className='col community_publication_send_col' onClick={() => handleShowPublicationRepostForm(post.id)}>
                    <h4 className='community_publication_options_text'><img src="/images/Vector_send.svg" className='publication_options_icon'/> Reenviar</h4>
                  </div>
                </div>
                {isCommentsVisible[post.id] && (
                  <div className='comments_div'>
                    {commentsDictionary[post.id] && commentsDictionary[post.id].map((comment) => (
                      <div className='comment_div'>
                        <div className='col publication_delete_option'>
                          <img src='/images/cancel_icon.svg' className='publication_comment_cancel_icon' onClick={() => handleDeleteComment(comment.id)}/>
                        </div>
                        <h4 className='comment_text'>{comment.content}</h4>
                      </div>
                    ))}
                  </div>
                )}
                <div className='row community_publication_comment'>
                  <div className='col'>
                    <div>
                      <img src={imagen_perfil} className='comment_profile_img'/>
                      <img src='/images/circle.svg' className='publication_profile_circle1'/>
                    </div>
                  </div>
                  <div className='col' key={post.id}>
                    <form className='box comment_form' onSubmit={(e) => handleCreateComment(e, post.id)}>
                      <input
                        className='community_comment_input'
                        placeholder='Escribe algo...'
                        onChange={(e) => setCreateComment(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="community-rectangulo-arriba">

          <div className='row'>
            <div className='col'>
              <img src='/images/empty_ellipse.svg' className='right_col_image_ellipse'/>
            </div>
            <div className='col details_community_right_col'>
              <p className="single_community_name">{community.name}</p>
              <p className="single_community_followers"> Grupo público - {community.followers} miembros</p>
            </div>
          </div>

            <div className="flex-container">
            </div>
            
            <button className="button-seecommunity">
              <div className='row'>
                <div className='col'>
                  <img src='/images/home_icon.svg' className='community_home_icon'/>
                </div>
                <div className='col'>
                  <h4 className='community_info_button_title'>Inicio de la comunidad</h4>
                  <h6 className='community_info_button_subtitle'>5 publicaciones</h6>
                </div>
                <div className='col'>
                  <img src='/images/number_publications.svg' className='community_number_publications_icon'/>
                </div>
              </div>
            </button>
            {isMember &&(
              <button className='community_invite_button'>
                Invitar
              </button>
            )}
            <div className='community_sub_info'>
              <div className='row'>
                <h1 className='community_left_block_subtitle'>Información</h1>
              </div>
              <div className='row'>
                <p className='community_left_block_text'>{community.description}</p>
              </div>
              <div className='row'>
                <h1 className='community_left_block_subtitle'>Privado</h1>
              </div>
              <div className='row'>
                <p className='community_left_block_text'>Este grupo es de discusión general para egresados de la Universidad Adolfo Ibáñez 
                (tanto Viña como Santiago).</p>
              </div>
              <div className='row'>
                <h1 className='community_left_block_subtitle'>Visible</h1>
              </div>
              <div className='row'>
                <p className='community_left_block_text'>Cualquier persona puede encontrar este grupo.</p>
              </div>
              <div className='row'>
                <h1 className='community_left_block_subtitle'>Puede incluir contenido marcado</h1>
              </div>
              <div className='row'>
                <p className='community_left_block_text'>Es posible que los administradores permitan que algunas publicaciones y comentarios se muestren
                en el grupo aunque estén marcados por los sistemas de WOT. </p>
              </div>
              <button className='community_more_information_button'>
                Más información
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
      )}
    </div>
    ):null}</>
  );
  
}

export default Comunidad;
