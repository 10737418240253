import React, { useState } from 'react';
import '../ForgotPassword/styles.css';
import { forgot_password } from '../../api';
import { useNavigate } from 'react-router-dom';
import { ImagesLogin } from './ImagesLogin';
import { FormInput } from './FormInput';
import Cookies from 'js-cookie';
import { LoggingAlert, ErrorAlert } from './Alerts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ForgotPassword() {

  const [formData, setFormData] = useState({
    mail: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    try {
      let response = await forgot_password(formData.mail);
      if (response.status === 201) {
        navigate('/enterverificationcode', { state: { mail: formData.mail } });
      }
      else if (response.status !== 201){
        /*setError(true);
        toast.error('El correo electrónico no coincide con una cuenta de WoT', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });*/
          navigate('/enterverificationcode', { state: { mail: formData.mail } });
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {/* {loading && <LoggingAlert text={'Enviando código'}/>}
      {error && <ErrorAlert text={'El correo electrónico no coincide con una cuenta de WoT'}/>} */}
      <ToastContainer />
      <div className='main-container-login'>
          <ImagesLogin />
          <FormInput handleInputChange={handleInputChange}
                     handleLogin={handleLogin} 
                     message={'Ingresa tu correo para recuperar tu contraseña'}
                     input={'Correo electrónico'}
                     buttonText={'Enviar código'}
                     type={'mail'} />
      </div>
    </>
  )
  
}

export default ForgotPassword;