import axios from 'axios';

//export const BASE_URL = "https://6x4alnkj31.execute-api.us-east-1.amazonaws.com/prod-wot-backend";
//export const BASE_URL = "https://e10rm1jli6.execute-api.us-east-1.amazonaws.com/dev-wot-backend";
export const BASE_URL = "http://localhost:3000";


// Usuario
export async function signup(name, lastName, mail, password, recaptchaToken) {
    try {
        const response = await fetch(`${BASE_URL}/auth/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                name,
                lastName,
                mail,
                password,
                recaptchaToken,
            }),
        });
        // if (!response.ok) {to
        //     throw new Error('Failed  sign up');
        // }
        return response;

    } catch (error) {
        // Handle errors here
        console.log(error.message);
        console.error(error);
    }
}

export async function login(mail, password) {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                mail,
                password,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to login');
        }
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export  async function refresh_token(token){
    try {
        const response = await fetch(`${BASE_URL}/auth/refresh_token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Set the content type to JSON
            },
            body: JSON.stringify({
                "refreshToken": token
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to login');
        }
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}


// Users

export async function get_users(token) {
    try {
        const response = await fetch(`${BASE_URL}/users/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }});
        return response;

    } catch (error) {
        console.error(error);
    }
}

// Obtener user en perfil, ya sea registrandose o con login
// Debe star protegida. No cualquier usuario debe tener acceso a los datos de un usuario
export async function get_profile(token) {
    try {
        const response = await fetch(`${BASE_URL}/users/get_profile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }});
        return response;

    } catch (error) {
        console.error(error);
    }
}

// Login with Google
export async function google_login(code) {
    try {
        const response = await axios.post(
            `${BASE_URL}/auth/google_login`,
            {
            code: code,
            },
        );
        return response;
    } catch (error) {
        console.error(error);
    }
  }

// Eliminar cuenta de user
export async function delete_user(token) {
    try {
        const response = await fetch(`${BASE_URL}/users/delete_account`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }});
        return response;

    } catch (error) {
        console.error(error);
    }
}

export async function forgot_password(mail) {
    try {
        const response = await fetch(`${BASE_URL}/auth/forgot_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail,
            }),
        });
        return response;
    
    } catch (error) {
        console.error(error);
    }
}

export async function reset_password(mail, code, newPassword) {
    try {
        const response = await fetch(`${BASE_URL}/auth/reset_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                mail,
                code,
                newPassword,
            }),
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function change_password(email, currentPassword, newPassword) {
    try {
        const response = await fetch(`${BASE_URL}/auth/change-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                currentPassword,
                newPassword,
            }),
        });
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function patch_user(token, json){
    try {
        const response = await fetch(`${BASE_URL}/users/edit_profile`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(json),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`Error en la solicitud PATCH: ${errorMessage}`);
        }

        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function update_cv(token, formData){
    try{
        const response = await axios.patch(`${BASE_URL}/users/updatecv`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function get_cv(token){
    try{
        const response = await axios.get(`${BASE_URL}/users/files/getcv`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        return error;
    }
}
//Comunidades

export async function get_communities(token) {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
  
      const response = await axios.get(`${BASE_URL}/communities/joinable_communities`, {
        headers: headers,
      });
      return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }


export async function get_community(token, community_id) {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
  
      const response = await axios.get(`${BASE_URL}/communities/${community_id}`, {
        headers: headers,
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

export async function get_user_communities(token) {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
  
      const response = await axios.get(`${BASE_URL}/communities/memberships`, {
        headers: headers,
      });
      return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }

export async function create_community(name, description, token) {
    try {
        const response = await fetch(`${BASE_URL}/communities/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                name,
                description,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to create community');
        }
        const data = await response.json();
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function edit_community(token, community_id, name, description) {
    try {
        const response = await fetch(`${BASE_URL}/communities/${community_id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                name,
                description,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to create community');
        }
        const data = await response.json();
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function delete_community(token, community_id) {
    try {
      const response = await fetch(`${BASE_URL}/communities/${community_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });  
      if (!response.ok) {
        throw new Error('Failed to delete community');
      }
      return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Rethrow the error to propagate it to the calling function
    }
  }

  export async function join_community(token, communityId) {
    try {
        const response = await fetch(`${BASE_URL}/communities/join`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                communityId,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to join community');
        }
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function leave_community(token, communityId) {
    try {
        const response = await fetch(`${BASE_URL}/communities/leave/${communityId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to leave community');
        }
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

///communities/find_by_nombre
export async function search_community(token, bodyRequest) {
    try {
        const response = await fetch(`${BASE_URL}/communities/find_by_nombre`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyRequest)
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


// Publicaciones

//getCommunityPosts

export async function get_publication(token, postId) {
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };
        const response = await axios.get(`${BASE_URL}/posts/${postId}`, {
            headers: headers,
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_feed_publications(token, mode, scrollLevel) {
    try {
        const response = await fetch(`${BASE_URL}/posts/get_feed_posts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                mode,
                scrollLevel,
            }),
        });
        return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }


export async function get_community_publications(token, communityId, mode, scrollLevel) {
    try {
        const response = await fetch(`${BASE_URL}/posts/get_community_posts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                communityId,
                mode,
                scrollLevel,
            }),
        });
        return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }

  export async function create_publication(token, communityId, content, imageFile) {
    try {
        const formData = new FormData();
        formData.append('multimedia', imageFile);
        formData.append('communityId', communityId);
        formData.append('content', content);
        const response = await fetch(`${BASE_URL}/posts/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData,
        });

        if (!response.ok) {
            console.log(response);
            throw new Error('Failed to create publication');
        }

        const data = await response.json();
        return data; // Returning data instead of response
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to handle it at a higher level if needed
    }
}

export async function edit_publication(token, publicationId, content) {
    try {
        const response = await fetch(`${BASE_URL}/posts/${publicationId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                content,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to edit publication');
        }
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function delete_publication(token, publicationId) {
    try {
      const response = await fetch(`${BASE_URL}/posts/${publicationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });  
      if (!response.ok) {
        throw new Error('Failed to delete publication');
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

export async function like_publication(token, publicationId) {
    try {
        const response = await fetch(`${BASE_URL}/posts/${publicationId}/like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error('Failed to like publication');
        }
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function repost_publication(token, originalPostId, newCommunityId, repostContent) {
    try {
        const response = await fetch(`${BASE_URL}/posts/repost`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                newCommunityId,
                originalPostId,
                repostContent,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to repost publication');
        }
        console.log(response.status);
        return response;

    } catch (error) {
        // Handle errors here
        console.error(error.message);
    }
}


// Comentarios

export async function get_comments(token, postId) {
    try {
      const headers = {
        'Authorization': `Bearer ${token}`,
      };
      const response = await axios.get(`${BASE_URL}/posts/${postId}/comments`, {
        headers: headers,
      });
      return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }


export async function create_comment(token, postId, content) {
    try {
        const response = await fetch(`${BASE_URL}/posts/${postId}/comment`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                content,
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to create comment');
        }
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
    }
}

export async function delete_comment(token, commentId) {
    try {
      const response = await fetch(`${BASE_URL}/posts/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete publication');
      }
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

// Listas datos


//router.get('/disponibilidades', controller.getDisponibilidades);
export async function get_disponibilidades() {
    try {
      const response = await axios.get(`${BASE_URL}/obtener_listas/disponibilidades`);
      return response;
    } catch (error) {
      // Handle errors here
      console.error(error);
      throw error; // Re-throw the error to propagate it further if needed
    }
  }

//router.get('/jornadas', controller.getJornadas);
export async function get_jornadas() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/jornadas`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/modalidades', controller.getModalidades);

export async function get_modalidades() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/modalidades`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/areas', controller.getAreas);
export async function get_areas() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/areas`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/regiones', controller.getRegiones);
export async function get_regiones() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/regiones`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/profesiones', controller.getProfesiones);
export async function get_profesiones() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/profesiones`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/industrias', controller.getIndustrias);
export async function get_industrias() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/industrias`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/competencias', controller.getCompetencias);
export async function get_competencias() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/competencias`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/idiomas', controller.getIdiomas);
export async function get_idiomas() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/idiomas`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/formularios', controller.getFormularios);
export async function get_formularios() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/formularios`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/rangoAnos', controller.getRangoAnos);
export async function get_rango_anos() {
    try {
        const response = await axios.get(`${BASE_URL}/obtenerListas/rango_anos`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/paises', controller.getPaises);
export async function get_paises() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/paises`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/universidades', controller.getUniversidades);
export async function get_universidades() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/universidades`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/cargos', controller.getCargos);
export async function get_cargos() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/cargos`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/aniosExperiencia', controller.getAniosExperiencia);
export async function get_anos_experiencia() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/anios_experiencia`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/conocioWot', controller.getConocioWot);
export async function get_conocio_wot() {
    try {
        const response = await axios.get(`${BASE_URL}/obtener_listas/conocio_wot`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/anosFundacion', controller.getAnosFundacion);
export async function get_anos_fundacion() {
    try {
        const response = await axios.get(`${BASE_URL}/obtenerListas/anos_fundacion`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/ingresosAnuales', controller.getIngresosAnuales);
export async function get_ingresos_anuales() {
    try {
        const response = await axios.get(`${BASE_URL}/obtenerListas/ingresos_anuales`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

//router.get('/cantidadEmpleados', controller.getCantidadEmpleados);
export async function get_cantidad_empleados() {
    try {
        const response = await axios.get(`${BASE_URL}/obtenerListas/cantidad_empleados`);
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_empresa_nombre(token, bodyRequest){
    try {
        const response = await fetch(`${BASE_URL}/companies/get_by_nombre` ,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(bodyRequest)
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_empresa_profile(token, id){
    try {
        const response = await fetch(`${BASE_URL}/companies/get_company_profile/${id}` ,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_notifications(token){
    try {
        const response = await fetch(`${BASE_URL}/notifications` ,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_notifications_preferences(token){
    try {
        const response = await fetch(`${BASE_URL}/notifications/get_notification_preferences` ,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function edit_notifications_preferences(token, dict){
    try {
        const response = await fetch(`${BASE_URL}/notifications/edit_notification_preferences` ,{
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body : JSON.stringify(dict),
        });
        return response;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}


export async function get_notifications_number(token){
    try {
        const response = await fetch(`${BASE_URL}/notifications/get_unseen_notifications_number` ,{
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export async function get_recomendation_comunities(token){
    try {
        const response = await fetch(`https://izhmg003mh.execute-api.us-east-2.amazonaws.com/default/wotrecommendercommunities` ,{
            method:"GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        // Handle errors here
        console.error(error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

