import React, { useEffect, useState, useRef } from 'react';

import './styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { get_communities, get_user_communities, get_users, get_profile,
  get_community_publications, delete_publication, like_publication,
  join_community, edit_publication, create_comment, delete_comment, get_comments,
  leave_community, repost_publication, search_community, get_feed_publications} from '../../api';
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

//Mui
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Icons
import {BsThreeDots, BsGearFill} from 'react-icons/bs';
import {BiSolidChevronUpCircle, BiSolidChevronDownCircle} from 'react-icons/bi';
import {AiFillHeart} from 'react-icons/ai';
import {AiOutlineSend} from 'react-icons/ai';
import {MdDone} from 'react-icons/md';
import { DeletePublicationAlert, ErrorDeletePublicationAlert, 
  EditPublicationAlert, ErrorEditPublicationAlert, CreateCommentAlert, ErrorCreateCommentAlert, 
        DeleteCommentAlert, ErrorDeleteCommentAlert, JoinComunityAlert, ErrorJoinComunityAlert,
         SalirseComunityAlert, ErrorSalirseComunityAlert, RepostAlert, ErrorRepostAlert} from './Alerts';

import {IoMdCreate} from 'react-icons/io';
import {BiPencil} from 'react-icons/bi';

//Images
import { ReactComponent as FeedIcon } from './icons/board.svg';
import { ReactComponent as TargetIcon } from './icons/target.svg';
import { ReactComponent as CommunitiesIcon } from './icons/communities_icon.svg';
import imagen_perfil from "./imagenes/perfil_logo.png"


function Comunidades() {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isFeedClicked, setIsFeedClicked] = useState(true);
  const [isFindClicked, setIsFindClicked] = useState(false);
  const [isGroupsClicked, setIsGroupsClicked] = useState(false);

  const [communities, setCommunities] = useState([]);
  const [likedCommunities, setLikedCommunities] = useState([]);
  const [yourCommunities, setYourCommunities] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [searchedCommunities, setSearchedCommunities] = useState([]);


  const [currentUser, setCurrentUser] = useState();

  const [posts, setPosts] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [publicationImages, setImages] = useState({});
  const [publicationDates, setDates] = useState([]);

  

  const [isEditFormVisible, setIsEditFormVisible] = useState({});
  const {register, handleSubmit } = useForm();

  const [comments, setComments] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const [isCommentsVisible, setIsCommentsVisible] = useState({});
  const [commentInputs, setCommentInputs] = useState({});

  const [completedForm,setCompletedForm] = useState(true);

  const [isRepostFormVisible, setIsRepostFormVisible] = useState({});
  const [selectedOption, setSelectedOption] = useState('');

  const [baseUrl, setBaseUrl] = useState('');

  const [publicationsLoading, setPublicationsLoading] = useState(false);
  const [recomendationsLoading, setRecomendationsLoading] = useState(false);
  const [canFetch, setCanFetch] = useState(true);

  const inputRef = useRef(null);
  const [paginaActual, setPaginaActual] = useState(1);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [recomendaciones, setRecomendaciones] = useState([]);


  const fetchCommunities = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_communities(token);
      const responseData = await response.data;
      setCommunities(responseData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setCompletedForm(false);
        toast.error('Completa tu formulario para acceder a comunidades, seras redireccionada a tu perfil', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(function() {
          navigate("/perfil");
        }, 6000);
        
      }
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    setRecomendationsLoading(true);
    const interval = setInterval(() => {
        const datosEnCookie = Cookies.get("recomendaciones");
        if (datosEnCookie) {
            try {
                setRecomendaciones(JSON.parse(datosEnCookie));
            } catch (error) {
                console.error('Error al parsear los datos de la cookie:', error);
            }
            clearInterval(interval); // Detiene el intervalo una vez que los datos están cargados
            setRecomendationsLoading(false);
        }
    }, 5000); // Verifica las cookies cada 5 segundo

    return () => clearInterval(interval); // Limpieza en el desmontaje
  }, []);

  const fetchYourCommunities = async () => {
    setLoadingState(true); // Set loading state to true before making the API call
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_user_communities(token);
      const responseData = await response.data;
      setLikedCommunities(responseData.otherCommunities);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingState(false); // Set loading state to false after the API call is complete
    }
  };

  const handleCommunityClick = (communityId) => {

    navigate(`/comunidad/${communityId}`);
  };

  const fetchCurrentUser = async () => {
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_profile(jwtToken);
      const responseData = await response.json();
      setCurrentUser(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchFeedPublications = async (pagina) => {
    try {
      setPublicationsLoading(true); // Set loading to true before making the request
      await fetchCurrentUser();
      const token = Cookies.get('jwtToken');
      const allPosts = [];
      const allCargos = [];
      const allImages = {};
      const allDates = [];
      const response = await get_feed_publications(token, "createdAt", pagina);
      const responseData = await response.json();
      console.log(responseData.postMultimediaArray);
      allPosts.push(...responseData.posts);
      allCargos.push(...responseData.cargosArray);
      allDates.push(...responseData.dateTimeArray);
      await Promise.all(responseData.postMultimediaArray.map(async (image, index) =>{
        if (image.length!==0){
          if (responseData.posts[index].id in allImages) {
            allImages[responseData.posts[index].id].push(image[0]);
          } else {
            allImages[responseData.posts[index].id] = [image[0]];
          }
        }
      }));
      setPosts(prevPosts=>[ ...prevPosts, ...allPosts]);
      setCargos(prevCargos => [...prevCargos,...allCargos]);
      setImages(prevImages => ({
        ...prevImages,
        ...allImages
      }));
      setDates(prevDates =>[...prevDates, ...allDates]);     
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setPublicationsLoading(false);
    }
  };

  const handleLikePost = async (postId) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await like_publication(token, postId);
      const nuevosPosts = [...posts];
      const postIndex = nuevosPosts.findIndex((post) => post.id === postId);
      if (postIndex !== -1) {
        if (nuevosPosts[postIndex].liked === true){
          nuevosPosts[postIndex].liked = false;
        }
        else{
          nuevosPosts[postIndex].liked = true;
        }
      setPosts(nuevosPosts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };  

  const [menuVisibility, setMenuVisibility] = useState({});
  
  const toggleMenu = (userId) => {
      setMenuVisibility((prevVisibility) => ({
        ...prevVisibility,
        [userId]: !prevVisibility[userId] || false,
      }));
  };

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
      setBaseUrl(window.location.origin);
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCommunities();
        await fetchCurrentUser();
        await fetchYourCommunities();
        
      } catch (error) {
        
        if (error.response && error.response.status === 401) {
          
          toast.error('Completa tu formulario para acceder a comunidades', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(function() {
          }, 3000);
          navigate("/perfil");
        } else {
          const errorStatus = error.response.status;
          console.error(errorStatus);
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
    // Resto de tu código...
  }, []);

  useEffect(() => {
    const fetchData = async () =>{
      

      if (paginaActual > 0){
        await fetchFeedPublications(paginaActual);
      setCanFetch(true);
      }
      
    }
    //setLoadingFeed(true);
    fetchData();
    //setLoadingFeed(false);
  }, [paginaActual, forceUpdate]);
 

  const NavButton = styled(Button)({
    backgroundColor: 'transparent',
    color: '#5A5A5A',
    width: '110%',
    borderRadius: '0px',
    border: '0px',
    height: '7vh',
    '&:hover': {
      backgroundColor: 'rgba(252, 4, 124, 0.1)',
      color: '#fc047c',
      borderLeft: '4px solid #fc047c',
    },
  });

  const ClickedNavButton = styled(Button)({
    backgroundColor: 'rgba(252, 4, 124, 0.1)',
    width: '110%',
    borderRadius: '0px',
    border: '0px',
    height: '7vh',
    borderLeft: '4px solid #fc047c',
    color: '#fc047c',
  });

  const FilledCustomThreeDots = styled(BsThreeDots)({
    color: 'white',
    marginTop: '1.9vh',
    backgroundColor: '#5A5A5A',
    borderRadius: '5px',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
    cursor: 'pointer',
  });

  const handleShowFeedClick = () => {
    setIsFeedClicked(true);
    setIsFindClicked(false);
    setIsGroupsClicked(false);
  };

  const handleShowFindClick = () => {
    setIsFeedClicked(false);
    setIsFindClicked(true);
    setIsGroupsClicked(false);
  };

  const handleShowGroupsClick = () => {
    setIsFeedClicked(false);
    setIsFindClicked(false);
    setIsGroupsClicked(true);
  };

  const [anchorElComunitie, setAnchorElComunitie] = useState(null);

  const handleClickComunitie = (event) => {
    setAnchorElComunitie(event.currentTarget);
  };

  const handleCloseComunitie = () => {
    setAnchorElComunitie(null);
  };
  const [anchorElComunitieLiked, setAnchorElComunitieLiked] = useState(null);

  const [menuStatesLiked, setMenuStatesLiked] = useState({});

  const handleClickComunitieLiked = (event, communityId) => {
    setAnchorEl(event.currentTarget);
    setAnchorElComunitieLiked(event.currentTarget);
    setMenuStatesLiked((prevState) => ({
      ...prevState,
      [communityId]: !prevState[communityId], // Cambiar el estado al contrario del valor actual
    }));
  };

  const handleCloseComunitieLiked = () => {
    setAnchorElComunitieLiked(null);
    setAnchorEl(null);
    setMenuStatesLiked({});
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuStates({});
  };
  const [menuStates, setMenuStates] = useState({});

  const handleCommunityClickMenu = (event, communityId) => {
    // Actualizar el estado del menú para la comunidad con el ID dado
    setAnchorEl(event.currentTarget);
    setMenuStates((prevState) => ({
      ...prevState,
      [communityId]: !prevState[communityId], // Cambiar el estado al contrario del valor actual
    }));
  };

  const [anchorElPen, setAnchorElPen] = useState(null);
  const [menuStatesEdit, setMenuStatesEdit] = useState({});

  const handleClickPen = (event, postId) => {
    setAnchorElPen(event.currentTarget);
    setMenuStatesEdit((prevState) => ({
      ...prevState,
      [postId]: !prevState[postId], // Cambiar el estado al contrario del valor actual
    }));
  };

  const handleClosePen = () => {
    setAnchorElPen(null);
    setMenuStatesEdit({});
  };
  
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed:1000,
    nextArrow: <button class="slick-next"></button>,
    prevArrow: <button class="slick-prev"></button>
  };

  const handleJoinCommunity = async (community_id) => {
    try {
      const token = Cookies.get('jwtToken');
      let response = await join_community(token, community_id);
      if (response.status === 200) {
        try {
          // Make a GET request to your API endpoint
          setJoinComunitySuccess(true);
          setTimeout(() => {
            setJoinComunitySuccess(false);
          }, 2000);
          const response = await get_communities(token);
          const responseData = await response.data;
          setCommunities(responseData);
          fetchYourCommunities();
        } catch (error) {
          setJoinComunityError(true);
          setTimeout(() => {
            setJoinComunityError(false);
          }, 2000);
          console.error('Error fetching data:', error);
        }
      }
    } catch (error) {
      alert('Error al seguir la comunidad');
      console.error(error);
    }
  };

  const editPublicationSubmit = async (data, publicationId) => {
    try {
      const content = data[`publication-${publicationId}`];
      const token = Cookies.get('jwtToken');
      let response = await edit_publication(token, publicationId, content);
      if (response.status === 200) {
        const nuevosPosts = [...posts];
        const postIndex = nuevosPosts.findIndex((post) => post.id === publicationId);
        if (postIndex !== -1) {
          nuevosPosts[postIndex].content = content;
        }
        setPosts(nuevosPosts);
        handleShowPublicationEditForm(publicationId);
        setEditPublicationError(false);
        setEditPublicationSuccess(true);
        setTimeout(() => {
          setEditPublicationSuccess(false);
        }, 2000);
      }
    } catch (error) {
      setEditPublicationError(true);
      setEditPublicationError(false);
      setTimeout(() => {
        setEditPublicationError(false);
      }, 2000);
    }
  };

  const handleShowPublicationEditForm = async (publicationId) => {
    handleClosePen();
    if (isEditFormVisible[publicationId]){
      toggleEditPublicationVisibility(publicationId);
    } else {
      toggleEditPublicationVisibility(publicationId);
    }
  };

  const toggleEditPublicationVisibility = (publicationId) => {
    setIsEditFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const handleSelectChange = (e) => {
    // Update the selectedOption state when the select value changes
    setSelectedOption(e.target.value);
  };

  const repostPublicationSubmit = async (e, postId) => {
    e.preventDefault();
  
    const content = e.target[`repost-publication-${postId}`].value;
    const selectedCommunityId = e.target.querySelector('select').value;
    try {
      const token = Cookies.get('jwtToken');
      let response = await repost_publication(token, postId, selectedCommunityId, content);
  
      if (response.status === 201) {
        // Successful repost
        setRepostError(false);
        setRepostSuccess(true);
        setPosts([]);
        setCargos([]);
        setImages({});
        setPaginaActual(prevCount => prevCount === 1 ? prevCount : 1);
        setForceUpdate(prevForceUpdate => !prevForceUpdate);
        setTimeout(() => {
          setRepostSuccess(false);
        }, 2000);
      }
    } catch (error) {
      // Error during repost
      setRepostError(true);
      setRepostSuccess(false);
      setTimeout(() => {
        setRepostSuccess(false);
      }, 2000);
    }
  };
  
  

  const handleShowPublicationRepostForm = async (publicationId) => {
    handleClosePen();
    if (isRepostFormVisible[publicationId]){
      toggleRepostPublicationVisibility(publicationId);
    } else {
      toggleRepostPublicationVisibility(publicationId);
    }
  };

  const toggleRepostPublicationVisibility = (publicationId) => {
    setIsRepostFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const handleDeletePublication = async (publicationId, index) => {
    const confirmed = window.confirm("¿Estas segura de que quieres eliminar la publicación?");
    if (confirmed) {
      try {
        const token = Cookies.get('jwtToken');
        let response = await delete_publication(token, publicationId);
        if (response.status === 200) {
          const nuevosPosts = posts.filter((post) => post.id !== publicationId);
          const nuevosCargos = cargos.splice(index,1);
          const nuevasImages =  { ...publicationImages };
          if (nuevasImages.hasOwnProperty(publicationId)) {
            delete updatedImages[publicationId];
          }
          setPosts(nuevosPosts);
          setCargos(nuevosCargos);
          setImages(nuevasImages);
          setDeletePublicationError(false);
          setDeletePublicationSuccess(true);
          setTimeout(() => {
            setDeletePublicationSuccess(false);
          }, 2000);
        }
      } catch (error) {
        setDeletePublicationError(true);
        setDeletePublicationSuccess(false);
        setTimeout(() => {
          setDeletePublicationError(false);
        }, 2000);
      }
    }
  };

  const fetchComments = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const commentsDict = {};
  
      const promises = posts.map(async (post) => {
        const response = await get_comments(token, post.id);
        const commentsData = response.data.comments;
  
        // Initialize the comments array for this post ID
        commentsDict[post.id] = commentsData;
      });
  
      // Wait for all promises to resolve
      await Promise.all(promises);
      setCommentsDictionary(commentsDict);
      // Set the comments dictionary to the state
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const fetchCommentsForPublication = async (postId) => {
    setLoadingStates(prevLoadingStates => {
      const updatedLoadingStates = [...prevLoadingStates];
      updatedLoadingStates[postId] = true;
      return updatedLoadingStates;
    });
    try {
      const token = Cookies.get('jwtToken');
      const response = await get_comments(token, postId);
      if (response.status === 200 ){
        setComments(prevComments => ({
          ...prevComments,
          [postId]: response.data.comments
        }));
        setLoadingStates(prevLoadingStates => {
          const updatedLoadingStates = [...prevLoadingStates];
          updatedLoadingStates[postId] = false;
          return updatedLoadingStates;
        });
      } else {
        setLoadingStates(prevLoadingStates => {
          const updatedLoadingStates = [...prevLoadingStates];
          updatedLoadingStates[postId] = false;
          return updatedLoadingStates;
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingStates(prevLoadingStates => {
        const updatedLoadingStates = [...prevLoadingStates];
        updatedLoadingStates[postId] = false;
        return updatedLoadingStates;
      });
    }
  };

  const handleCreateComment = async (e, postId) => {
    e.preventDefault();
  
    try {
      const token = Cookies.get('jwtToken');
      const inputValue = commentInputs[postId];
      const response = await create_comment(token, postId, inputValue);  
      if (response.status === 201) {
        fetchCommentsForPublication(postId);
        setCreateCommentError(false);
        setCreateCommentSuccess(true);
        handleCollapseComments(postId);
        setTimeout(() => {
          setCreateCommentSuccess(false);
        }, 2000);
      } else {
        setCreateCommentError(true);
        setCreateCommentSuccess(false);
        setTimeout(() => {
          setCreateCommentError(false);
        }, 2000);
      }
    } catch (error) {
      console.error('Error creating comment:', error);
      setCreateCommentError(true);
      setCreateCommentSuccess(false);
      setTimeout(() => {
        setCreateCommentError(false);
      }, 2000);
    }
  };

  const handleInputChange = (e, postId) => {
    // Update the input value for the specific post using postId as the key in commentInputs object
    setCommentInputs(prevInputs => ({
      ...prevInputs,
      [postId]: e.target.value
    }));
  };

  const handleDeleteComment = async (commentId, postId) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await delete_comment(token, commentId);
      if (response.status === 204) {
        fetchCommentsForPublication(postId);
        setDeleteCommentError(false);
        setDeleteCommentSuccess(true);
        setTimeout(() => {
          setDeleteCommentSuccess(false);
        }, 2000);
      } else {
        setDeleteCommentError(true);
        setDeleteCommentSuccess(false);
        setTimeout(() => {
          setDeleteCommentError(false);
        }, 2000);  
      }
    } catch (error) {
      setDeleteCommentError(true);
      setDeleteCommentSuccess(false);
      setTimeout(() => {
        setDeleteCommentError(false);
      }, 2000);  
    }
  };

  const [button1Active, setButton1Active] = useState(false);

  const getButtonClass = (buttonState) => {
    return buttonState ? 'publication_options_text2-activate' : 'publication_options_text2-inactivate';
  };

  const handleCollapseComments = async (publicationId) => {
    setButton1Active(!button1Active);
    if (isCommentsVisible[publicationId]){
      toggleCommentsVisibility(publicationId);
    } else {
      fetchCommentsForPublication(publicationId);
      toggleCommentsVisibility(publicationId);
    }
  };

  const toggleCommentsVisibility = (publicationId) => {
    setIsCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const [deletePublicationSuccess, setDeletePublicationSuccess] = useState(false);
  const [publicationDeleteError, setDeletePublicationError] = useState(false);

  const [editPublicationSuccess, setEditPublicationSuccess] = useState(false);
  const [editPublicationError, setEditPublicationError] = useState(false);

  const [createCommentSuccess, setCreateCommentSuccess] = useState(false);
  const [createCommentError, setCreateCommentError] = useState(false);

  const [deleteCommentSuccess, setDeleteCommentSuccess] = useState(false);
  const [commentDeleteError, setDeleteCommentError] = useState(false);

  const [repostSuccess, setRepostSuccess] = useState(false);
  const [repostError, setRepostError] = useState(false);
  const [joinComunitySuccess, setJoinComunitySuccess] = useState(false);
  const [joinComunityError, setJoinComunityError] = useState(false);

  const [salirComunitySuccess, setSalirComunitySuccess] = useState(false);
  const [salirComunityError, setSalirComunityError] = useState(false);

  const handleLeaveCommunityClick = async (community_id) => {
    setLoadingState(true);
    try {
      const token = Cookies.get('jwtToken');
      let response = await leave_community(token, community_id);
      if (response.status === 204) {
        try {
          setSalirComunitySuccess(true);
          setTimeout(() => {
            setSalirComunitySuccess(false);
          }, 2000);
          await fetchCommunities();
          await fetchYourCommunities();
          await fetchFeedPublications();
          setLoadingState(false);
        } catch (error) {
          setSalirComunityError(true);
          setTimeout(() => {
            setSalirComunityError(false);
          }, 2000);
          console.error('Error fetching data:', error);
          setLoadingState(false);
        }
      }
    } catch (error) {
      alert('Error al salir de la comunidad');
      console.error(error);
      setLoadingState(false);
    }
  };
  
  const [puedeCargar, setPuedeCargar] = useState(true);

  useEffect(() => {
    let scrollElement;
    let canFetch = true; // Variable para controlar si se puede realizar la solicitud
  
    const delayedGetScrollElement = () => {
      scrollElement = document.getElementById('scrollElement');
      if (scrollElement) {
        const handleScroll = async () => {
          const scrollHeight = scrollElement.scrollHeight;
          const visibleHeight = scrollElement.clientHeight;
          const scrollTop = scrollElement.scrollTop;
          if (scrollHeight - scrollTop <= visibleHeight + 10 && canFetch) {
            setPuedeCargar(false);
            // Actualiza la variable canFetch para evitar solicitudes múltiples
            canFetch = false;
            setPaginaActual((pagina)=> pagina+1);
            await fetchYourCommunities()
            await fetchFeedPublications()
              .then(() => {
                // Habilita la posibilidad de realizar otra solicitud
                canFetch = true;
                setPuedeCargar(true); // Asegúrate de que se pueda cargar nuevamente
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                // Habilita la posibilidad de realizar otra solicitud incluso en caso de error
                canFetch = true;
                setPuedeCargar(true);
              });
          }
        };
  
        scrollElement.addEventListener('scroll', handleScroll);
  
        return () => {
          scrollElement.removeEventListener('scroll', handleScroll);
        };
      }
    };
  
    const delay = 100; // Ajusta el tiempo de retraso según sea necesario
  
    const timeoutId = setTimeout(() => {
      delayedGetScrollElement();
    }, delay);
  
    // Limpia el timeout si el componente se desmonta antes de que se ejecute
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const handleSearch = async () => {
    const inputValue = inputRef.current.value;
    const bodyRequest = {nombre: inputValue};
    const token = Cookies.get('jwtToken');
    const response = await search_community(token, bodyRequest);
    setSearchedCommunities(response);
    console.log(response);
}

  return (
    <><ToastContainer limit={1} />
    {completedForm ?( 
    <div className='center_content_div' >
      {isLoggedIn && (
        <>
          {deletePublicationSuccess && (
            <DeletePublicationAlert />
          )}
          {publicationDeleteError && (
            <ErrorDeletePublicationAlert />
          )}
          {editPublicationSuccess && (
            <EditPublicationAlert />
          )}
          {editPublicationError && (
            <ErrorEditPublicationAlert />
          )}
          {createCommentSuccess && (
            <CreateCommentAlert />
          )}
          {createCommentError && (
            <ErrorCreateCommentAlert />
          )}
          {deleteCommentSuccess && (
            <DeleteCommentAlert />
          )}
          {commentDeleteError && (
            <ErrorDeleteCommentAlert />
          )}
          {repostSuccess && (
            <RepostAlert />
          )}
          {repostError && (
            <ErrorRepostAlert />
          )}
          {joinComunitySuccess &&(
            <JoinComunityAlert/>
          )}
          {joinComunityError &&(
            <ErrorJoinComunityAlert/>
          )}
          {salirComunitySuccess &&(
            <SalirseComunityAlert/>
          )}
          {salirComunityError &&(
            <ErrorSalirseComunityAlert/>
          )}
          <div className='row'>
            <div className='col nav_col'>
              <div className='row header_nav'>
                <div className='col'>
                  <div className='profile_img_div'>
                    <img src={imagen_perfil} className='profile_img' />
                    <img src="/images/circle.svg" className='pink_circle' />
                  </div>
                </div>
                <div className='col'>
                  <h2 className='communities_nav_title'>Comunidades</h2>
                </div>
              </div>
              <div className='row'>
                <input         
                  ref={inputRef}
                  type="text"
                  onKeyDown={handleSearch} 
                  className='search_bar_communities' 
                  placeholder='Buscar grupos' 
                />
              </div>
              <div className='row nav_button_row'>
                {isFeedClicked && (
                  <ClickedNavButton variant="text" onClick={handleShowFeedClick}>
                    <div className='col nav_icon_col'>
                      <FeedIcon className='icon'></FeedIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Tu feed</h3>
                    </div>
                  </ClickedNavButton>
                )}
                {!isFeedClicked && (
                  <NavButton variant="text" onClick={handleShowFeedClick}>
                    <div className='col nav_icon_col'>
                      <FeedIcon className='icon_normal'></FeedIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Tu feed</h3>
                    </div>
                  </NavButton>
                )}
              </div>
              <div className='row nav_button_row'>
                {isFindClicked && (
                  <ClickedNavButton variant="text" onClick={handleShowFindClick}>
                    <div className='col nav_icon_col'>
                      <TargetIcon className='icon'></TargetIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Descubrir</h3>
                    </div>
                  </ClickedNavButton>
                )}
                {!isFindClicked && (
                  <NavButton variant="text" onClick={handleShowFindClick}>
                    <div className='col nav_icon_col'>
                      <TargetIcon className='icon_normal'></TargetIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Descubrir</h3>
                    </div>
                  </NavButton>
                )}
              </div>
              <div className='row nav_button_row'>
                {isGroupsClicked && (
                  <ClickedNavButton variant="text" onClick={handleShowGroupsClick}>
                    <div className='col nav_icon_col'>
                      <CommunitiesIcon className='icon'></CommunitiesIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Tus grupos</h3>
                    </div>
                  </ClickedNavButton>
                )}
                {!isGroupsClicked && (
                  <NavButton variant="text" onClick={handleShowGroupsClick}>
                    <div className='col nav_icon_col'>
                      <CommunitiesIcon className='icon_normal'></CommunitiesIcon>
                    </div>
                    <div className='col nav_option_col'>
                      <h3 className='nav_option_text'>Tus grupos</h3>
                    </div>
                  </NavButton>
                )}
              </div>
              <div className='groups_div'>
              {searchedCommunities.length > 0 && (
                <>
                  {searchedCommunities.map((community) => (
                    <div className='community_div row' key={community.id}>
                      <div className='col'>
                        <img src='/images/nav_communities_icon.svg' className='communities_list_icon'/>
                      </div>
                      <div className='col'>
                        <h1 className='community_title' onClick={() => handleCommunityClick(community.id)}>{community.name}</h1>
                        <h3 className='community_followers'>{community.followers} seguidores</h3>
                      </div>
                    </div>
                  ))}
                </>
              )}
              </div>
            </div>
            <div className='col content_col'>
              {isFeedClicked && (
                <>
                  <div className='contenedor_blanco'>
                    <h1 className='sub_title_communities'>Actividad reciente</h1>
                  </div>
                  <div className='publications_div'  id='scrollElement'>
                  {publicationsLoading && (
                    <div className="spinner"></div>
                  )}
                    {posts.map((post, index) => (
                      <div className='publication_div'>
                        <div className='publication_text_div'>
                          {currentUser.id == post.userId ? (
                            <div className='row publication_options_div'>
                              <div className='col publication_edit_option'>
                                <BiPencil id={`menu-edit-${post.id}`} aria-controls={`menu-edit-${post.id}`} className='publication_edit_button1' size={20} onClick={(event)=>handleClickPen(event,post.id)}/>
                                    <Menu
                                    id={`menu-edit-${post.id}`}
                                    anchorEl={anchorElPen}
                                    open={menuStatesEdit[post.id] || false}
                                    onClose={handleClosePen}
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <MenuItem onClick={() => handleShowPublicationEditForm(post.id)}>Editar Publicación</MenuItem>
                                    <MenuItem onClick={() => handleDeletePublication(post.id, index)}>Eliminar Publicación</MenuItem>
                                  </Menu>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className='row date_row'>
                          </div>
                          <div className="row flex-container">
                            <div className='col publication_user_image'>
                              <div className='comment_profile_circle1'>
                                <img src={imagen_perfil} className='community_publication_profile_circle2' />
                              </div>
                            </div>
                            <div className='col'>
                              <h4 className='cargo_publication_text'>{cargos[index]}</h4>
                              <h4 className='date_text'>{publicationDates[index][0]} - {publicationDates[index][1]}</h4>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col publication_content_div'>
                              {!isEditFormVisible[post.id] && (
                                <div>
                                  <h1 className='publication_text'>{post.content}
                                  {post.is_repost &&(
                                    <a className="repost_link" href={`${baseUrl}/publicacion/${post.repostedPost.originalPostId}`}>Ver publicación</a>
                                  )}
                                  </h1>
                                  {publicationImages[post.id]?.map((image) => (
                                    <img src={image} alt="" className='publication_image' />
                                  ))}
                                </div>
                              )}
                              {isEditFormVisible[post.id] && (
                                <form
                                  className='edit_publication_form'
                                  onSubmit={(e) => handleSubmit((data) => editPublicationSubmit(data, post.id))(e)}
                                >
                                  <div className='row'>
                                    <div className='col'>
                                      <textarea
                                        className='publication_edit'
                                        {...register(`publication-${post.id}`)}
                                        placeholder='Publicación'
                                        style={{
                                          width: '50vw',
                                          height: '7vh',
                                          padding: '10px',
                                          border: '1px solid #ccc',
                                          borderRadius: '10px',
                                          boxSizing: 'border-box',
                                          fontSize: '16px',
                                          marginLeft: '3vw',
                                          resize: 'none',
                                          backgroundColor: '#FAFAFA',
                                          transition: 'border-color 0.3s ease',
                                        }}  
                                      defaultValue={post.content} />
                                    </div>
                                    <div className='col buttons_row_edit'>
                                      <button type='submit' className='edit_community_button'>
                                        <MdDone size={14} />
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              )}
                              {isRepostFormVisible[post.id] && (
                                <div className='repost_div'>
                                  <img
                                    src='/images/cancel_icon.svg'
                                    className='publication_repost_cancel_icon'
                                    onClick={() => handleShowPublicationRepostForm(post.id)} 
                                  />
                                  <h5 className='repost_title'>Reenvía esta publicación<img src="/images/Vector_send.svg" className='publication_options_icon_repost' /></h5>
                                  <form
                                    className='repost_publication_form'
                                    onSubmit={(e) => repostPublicationSubmit(e, post.id)}
                                  >
                                    <div className='row'>
                                      <label>
                                        <h6 className='repost_community_title'>Comunidad:</h6>
                                        <select value={selectedOption} onChange={handleSelectChange} className='repost_dropdown'>
                                          <option value="">Select...</option>
                                          {likedCommunities.map((community) => (
                                            <option key={community.id} value={community.id}>
                                              {community.name}
                                            </option>
                                          ))}
                                        </select>
                                      </label>
                                  
                                      <div className='col'>
                                        <textarea
                                          className='publication_repost_textarea'
                                          {...register(`repost-publication-${post.id}`)}
                                          placeholder='Publicación'
                                          style={{ height: '8vh', width: '22vw', maxWidth: '22vw', minWidth: '22vw', borderRadius: '10px', margin: 'auto' , maxHeight: '22vh'}}
                                          defaultValue={`Miren este post!`}
                                        />
                                      </div>
                                      <div className='col buttons_row_edit'>
                                        <button type='submit'className="repost_button"><AiOutlineSend size={18}/></button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='border_cafe'></div>
                        <div className='contenedor_botones'>
                          {post.liked && (
                            <div className='col publication_like_col' onClick={() => handleLikePost(post.id)}>
                              <h4 className='liked_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='liked_publication_options_icon' onClick={() => handleLikePost(post.id)} /> Me gusta</h4>
                            </div>
                          )}
                          {!post.liked && (
                            <div className='col publication_like_col' onClick={() => handleLikePost(post.id)}>
                              <h4 className='publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='publication_options_icon' onClick={() => handleLikePost(post.id)} /> Me gusta</h4>
                            </div>
                          )}

                          <button className="publication_options_button" onClick={() => handleCollapseComments(post.id)}>
                            <img src="/images/comment_logo.svg" className='publication_options_icon' alt='Comment Icon' />
                            <span className='publication_options_text'>Comentarios</span>
                          </button>

                          <div className='col publication_send_col' onClick={() => handleShowPublicationRepostForm(post.id)}>
                            <h4 className='publication_options_text2'><img src="/images/Vector_send.svg" className='publication_options_icon' /> Reenviar</h4>
                          </div>
                        </div>
                        {isCommentsVisible[post.id] && (
                          <>
                            {loadingStates[post.id] && <div className="spinner"></div>}
                            {comments[post.id] &&
                              comments[post.id].map(comment => (
                                <div key={comment.id} className='comment_div'>
                                  <div className='col publication_delete_option'>
                                    <img
                                      src='/images/cancel_icon.svg'
                                      className='publication_comment_cancel_icon'
                                      onClick={() => handleDeleteComment(comment.id, post.id)} />
                                  </div>
                                  <h4 className='comment_text'>{comment.content}</h4>
                                </div>
                              ))}
                          </>
                        )}
                        <div className='row publication_comment'>
                          <div className='col'>
                            <div>
                              <img src={imagen_perfil} className='comment_profile_img' />
                              <img src='/images/circle.svg' className='publication_profile_circle' />
                            </div>
                          </div>
                          <div className='col' key={post.id}>
                            <form className='box comment_form' onSubmit={(e) => handleCreateComment(e, post.id)}>
                              <input
                                className='publication_comment_input'
                                placeholder='Escribe algo...'
                                value={commentInputs[post.id] || ''} // Set input value from commentInputs object
                                onChange={(e) => handleInputChange(e, post.id)} // Pass postId to handleInputChange
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {isFindClicked && (
                <>
                  <div className='sugested_communities_div'>
                    <div className='row'>
                      <h1 className='sugested_communities_title'>Sugerencias de comunidades</h1>
                    </div>
                    <div className='row'>
                      <div className='sugested_communities-container'>
                      {recomendationsLoading && (
                      <div className="spinner"></div>
                        )}
                        <Slider {...settings}>
                          {recomendaciones.map((community) => (
                            <div className='sugested_community_div'>
                              <img src='/images/cancel_icon.svg' className='sugested_community_cancel_icon' />
                              <div className='humans_circle'>
                                <img src='/images/nav_communities_icon.svg' className='humans_circle_icon' />
                              </div>
                              <h1 className='sugested_community_name'>{community.name}</h1>
                              <h3 className='sugested_community_followers'>{community.followers} seguidores</h3>
                              <div className='follow_community_button' onClick={() => handleJoinCommunity(community.id)}>
                                <h4 className='follow_community_button_text'>Unirse</h4>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                    <div className='row'>
                      <h1 className='sugested_communities_title'>Mas sugerencias</h1>
                    </div>
                    <div className='sugested_communities-container'>
                      <Slider {...settings}>
                        {communities.map((community) => (
                          <div className='sugested_community_div'>
                            <img src='/images/cancel_icon.svg' className='sugested_community_cancel_icon' />
                            <div className='humans_circle'>
                              <img src='/images/nav_communities_icon.svg' className='humans_circle_icon' />
                            </div>
                            <h1 className='sugested_community_name' onClick={() => handleCommunityClick(community.id)}>{community.name}</h1>
                            <h3 className='sugested_community_followers'>{community.followers} seguidores</h3>
                            <div className='follow_community_button' onClick={() => handleJoinCommunity(community.id)}>
                              <h4 className='follow_community_button_text'>Unirse</h4>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </>
              )}
              {isGroupsClicked && (
                <>
                  <div className='other_communities_div'>
                    <div>
                      <div className='row'>
                        <h1 className='other_communities_title'>Tus grupos ({likedCommunities.length})</h1>
                      </div>
                     
                      <div className='row'>
                        <div className='other-communities-container'>
                          {likedCommunities.map((community) => (
                            <div className='other_community_div'>
                              <div className='liked_communities_humans_circle'>
                                <img src='/images/nav_communities_icon.svg' className='humans_circle_icon'/>
                              </div>                              
                              <h1 className='other_community_title'>{community.name}</h1>
                              <h3 className='community_last_seen'>Última visita: 15 semanas</h3>
                              <div className='row other_community_options_row'>
                                <div className='col'>
                                  <h3 className='see_community_badge' onClick={() => handleCommunityClick(community.id)}>Ver grupo</h3>
                                </div>
                                <div className='col'>
                                  <Button id={`menu-liked-${community.id}`} aria-controls={`menu-liked-${community.id}`}  aria-haspopup="true" onClick={(event) =>handleClickComunitieLiked(event,community.id)}><FilledCustomThreeDots/></Button>
                                  <Menu
                                    id={`menu-liked-${community.id}`} 
                                    anchorEl={anchorEl}
                                    open={menuStatesLiked[community.id] || false}
                                    onClose={handleCloseComunitieLiked}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      
                                    }}
                                  >
                                    <MenuItem onClick={handleCloseComunitieLiked}>Tu contenido</MenuItem>
                                    <MenuItem onClick={handleCloseComunitieLiked}>Compartir</MenuItem>
                                    <MenuItem onClick={handleCloseComunitieLiked}>Destacar contenido</MenuItem>
                                    <MenuItem onClick={() => {handleLeaveCommunityClick(community.id); handleCloseComunitieLiked}}>Abandonar comunidad</MenuItem>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>                    
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
    ): null}</>
  );
}

export default Comunidades;
