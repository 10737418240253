import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function SubmitButton({ handleLogin, buttonText, isDataVerified, acceptTerms }) {
  const handleSubmit = (event) => {
    handleLogin(event);
  };

  return (
    <Box className='box-user-login'>
      <Button onClick={handleSubmit} variant="contained" className='boton-submit'>
        {buttonText}
      </Button>
    </Box>
  );
}