import React, { useEffect, useState } from 'react';
import '../Header/styles.css';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {FaBell} from 'react-icons/fa';
import {LuLogOut} from 'react-icons/lu';
import { ReactComponent as ComunnitiesIcon } from './icons/communities_icon.svg';
import { ReactComponent as ProfileIcon } from './icons/profile_icon.svg';
import { ReactComponent as HomeIcon } from './icons/home_icon.svg';
import { ReactComponent as EmpresasIcon } from './icons/empresas_icon.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import LogoutModal from "./LogoutModal";
import { get_notifications_number } from '../../api';



function Header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [color, setColor] = useState("blue");
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const [notificactionNumber,setNotificationNumber] = useState(0);
  Modal.setAppElement('#root');

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    const fetchNumber = async () =>{
      const number = await get_notifications_number(jwtToken);
      console.log(number)
      setNotificationNumber(number.unseenNotificationsNumber);
    }
    try{
      fetchNumber()
    } catch (error){
      console.error(error)
    }
    
  }, []);

  const handleLogout = () => {
    setLogoutModalOpen(false);
    Cookies.remove('jwtToken');
    navigate('/login');
  }

  const handleOpenLogoutModal = () => {
    setLogoutModalOpen(true);
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  const handleLogin = () => {
    navigate('/login');
  };
  const location = useLocation();

  const isActive = (routePattern) => {
    return location.pathname.startsWith(routePattern);
  };
  // Patrones
  const communitiePattern = '/comunidades';
  const companyPattern = '/empresa';
  const profilePattern = '/perfil';
  const feedPattern = '/feed';
  const notificationPattern = '/notificaciones';
  const change_passwordPattern = '/change-password';

  return (
    <>
    <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link><link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
    <header>
      <Link to="/">
        <img className="logo" src="/images/logo.svg"  />
      </Link>
      <nav className='header_navigation'>
        <ul>
          {/* <li>
            <a href="/feed" className="navlink">
              <div className="icon-container">
                <HomeIcon className={isActive(feedPattern) ? 'navbar_icon activo' : 'navbar_icon'} />
                <span className={isActive(feedPattern) ? 'navlink-text activo' : 'navlink-text'}>Inicio</span>
              </div>
            </a>
          </li> */}
          {/* <li>
            <a href="/comunidades" className="navlink">
              <div className="icon-container">
                <ComunnitiesIcon className={isActive(communitiePattern) ? 'communities_icon activo' : 'communities_icon'} />
                <span className={isActive(communitiePattern) ? 'navlink-text activo' : "navlink-text"}>Comunidades</span>
              </div>
            </a>
          </li> */}
          {/* <li>
            <a href="/empresa_busqueda" className="navlink">
              <div className="icon-container">
                <EmpresasIcon className={isActive(companyPattern) ? 'empresas_icon activo' : 'empresas_icon'} />
                <span className={isActive(companyPattern) ? 'navlink-text activo' : "navlink-text"}>Empresas</span>
              </div>
            </a>
          </li> */}
          {isLoggedIn && (
            <>
              {/* <li>
                <a href="/notificaciones" className="navlink">
                  <div className="icon-container">
                    <span className={(notificactionNumber>0 ||  isActive(notificationPattern)) ?'navlink-text-number':'navlink-text-number-inactive'}>{notificactionNumber}</span>
                    <FaBell className={isActive(notificationPattern) ? 'navlink_icon activo' : 'navlink_icon'} />
                    <span className={isActive(notificationPattern) ? 'navlink-text activo' : 'navlink-text'}>Notificaciones </span>
                  </div>
                </a>
              </li> */}
              <li>
                <a href="/perfil" className="navlink">
                  <div className="icon-container">
                    <ProfileIcon className={isActive(profilePattern) ? 'profile_icon activo' : 'profile_icon'} />
                    <span className={isActive(profilePattern) ? 'navlink-text activo' : 'navlink-text'}>Perfil</span>
                  </div>
                </a>
              </li>
              <li>
                <a className="navlink" onClick={handleOpenLogoutModal}>
                  <div className="icon-container">
                    <LuLogOut className='navlink_icon'  />
                    <span className='navlink-text'>Cerrar Sesión</span>
                  </div>
                </a>
              <LogoutModal isOpen={isLogoutModalOpen} onClose={handleCloseLogoutModal} onLogout={handleLogout} />
              </li>
            </>
          )}
          {!isLoggedIn && (
            <li>
              <button className='login_button' onClick={handleLogin}>
                Ingresa
              </button>
            </li>
          )}
        </ul>
      </nav>
    </header></>
  );
}

export default Header;
