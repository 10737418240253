import React, { useState } from 'react';
import '../ForgotPassword/styles.css';
import { reset_password } from '../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { ImagesLogin } from './ImagesLogin';
import { FormInput2 } from './FormInput2';
import Cookies from 'js-cookie';
import { LoggingAlert, ErrorAlert } from './Alerts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function EnterVerificationCode() {
  const location = useLocation();
  const mail = location.state.mail;

  const [formData, setFormData] = useState({
    code: '',
    password: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    try {
      let response = await reset_password(mail, formData.code, formData.password);
      if (response.status === 201) {
        navigate('/login');
      }
      else if (response.status !== 201){
        setError(true);
        toast.error('El código o la contraseña son inválidas', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {/* {loading && <LoggingAlert text={'Cargando'}/>}
      {error && <ErrorAlert text={'El código o la contraseña son inválidas'}/>} */}
      <ToastContainer />
      <div className='main-container-login'>
          <ImagesLogin />
          <FormInput2 handleInputChange={handleInputChange} 
          handleLogin={handleLogin} 
          message={'Ingresa el código que enviamos a tu correo y tu nueva contraseña, recuerda revisar que el correo ingresado es el mismo de tu cuenta WOT'}
          input={'Código de verificación'}
          input2={'Nueva contraseña'}
          buttonText={'Confirmar'}
          type={'code'}
          type2={'password'}
          />
      </div>
    </>
  )
  
}

export default EnterVerificationCode;