import React from 'react';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
import '../Register/styles.css';
import { styled } from "@mui/material/styles";
import { BASE_URL } from '../../api';
import { Link } from 'react-router-dom';

const CustomButton = styled(Button)(() => ({
  fontFamily: 'Montserrat',
  width: '110px',
  height: '40px',
  borderRadius: '7px',
  fontSize: '12px',
  textAlign: 'center',
  textTransform: 'none',
  boxShadow: '0px 2px 2px 0px #00000040',
  margin: '10px',
  marginTop: '0px',
}));

export function GoogleLogin() {
  return (
    <Link to={`${BASE_URL}/auth/google`}>
      <CustomButton variant="outlined" startIcon={<GoogleIcon style={{ height: '15px' }} />}
        className='buttonGoogle'>
        Google
      </CustomButton>
    </Link>
  );
}
