import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { ForgotPassword } from './ForgotPassword';
import { SubmitButton } from './SubmitButton';
import { RegisterButton } from './RegisterButton';
import { MUItheme } from './MUItheme';
import { GoogleLogin } from './GoogleLogin';
import { LinkedinLogin } from './LinkedinLogin';

export function FormInput({ handleInputChange, handleLogin, handleRegister }) {

  return (
    <div className='right-container'>
      <ThemeProvider theme={MUItheme}>
        <h3 className='message-login'>Ingresa a WOT</h3>
        <h3 className='message-login-mobile'>Bienvenida a Wot</h3>
        {/* <GoogleLogin /> */}
        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
        <NormalInput text='Correo' onInputChange={handleInputChange}/>
        <HiddenInput text='Contraseña' onInputChange={handleInputChange}/>
        <ForgotPassword />
        <SubmitButton handleLogin={handleLogin}/>
        <RegisterButton handleRegister={handleRegister}/>
        <a className='button-forgot-password'>Si necesitas ayuda escribe a help@womantalent.cl</a>
      </ThemeProvider>
    </div>
  );
}
