import React, { useState } from 'react';
import '../Register/styles.css';
import { login } from '../../api';
import { useNavigate } from 'react-router-dom';
import { Images } from './Images';
import { FormInput } from './FormInput';
import Cookies from 'js-cookie';
import { LoggingAlert, ErrorAlert } from './Alerts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function Register() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    mail: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event, isDataVerified, acceptTerms) => {
    event.preventDefault();
    setError(false);
    setLoading(true);

    if (
      formData.nombre === '' || 
      formData.apellido === '' || 
      formData.mail === '' || 
      !formData.mail.includes('@') || 
      !isDataVerified || 
      !acceptTerms
    ) {
      setError(true);
      setLoading(false);
      toast.error('Ingresa tus datos correctamente', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      return;
    }

    navigate('/createpassword', { 
      state: { 
        nombre: formData.nombre, 
        apellido: formData.apellido, 
        mail: formData.mail } });
  };

  return (
    <>
      {/* {loading && <LoggingAlert text={'Enviando datos'}/>}
      {error && <ErrorAlert text={'Ingresa tus datos'}/>} */}
      <ToastContainer />
      <div className='main-container-login'>
          <Images />
          <FormInput 
            handleInputChange={handleInputChange} 
            handleLogin={handleLogin} 
          />
      </div>
    </>
  )
  
}

export default Register;