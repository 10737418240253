import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import { MUItheme } from './MUItheme';
import { GoogleLogin } from './GoogleLogin';
import { LinkedinLogin } from './LinkedinLogin';
import './styles.css';

export function FormInput({ handleInputChange, handleLogin }) {
  const [isDataVerified, setIsDataVerified] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [localError, setLocalError] = useState(false);

  const handleDataVerificationChange = () => {
    setIsDataVerified(!isDataVerified);
    setLocalError(false);
  };

  const handleAcceptTermsChange = () => {
    setAcceptTerms(!acceptTerms);
    setLocalError(false);
  };

  const handleLocalError = () => {
    setLocalError(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isDataVerified || !acceptTerms) {
      handleLocalError();
      return;
    }

    handleLogin(event, isDataVerified, acceptTerms);
  };

  return (
    <div className='right-container'>
      <ThemeProvider theme={MUItheme}>
        <h3 className='message-register'>Regístrate en WOT</h3>
        {/* <h3 className='enter-text'>Entrar con</h3> */}
        {/* <GoogleLogin /> */}
        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
        <NormalInput text='Nombre' value={'nombre'} onInputChange={handleInputChange}/>
        <NormalInput text='Apellido' value={'apellido'} onInputChange={handleInputChange}/>
        <NormalInput text='Email' value={'mail'} onInputChange={handleInputChange}/>

        <div className="checkbox-container">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={isDataVerified}
              onChange={handleDataVerificationChange}
            />
            Declaro que los datos ingresados son verídicos
          </label>

          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={acceptTerms}
              onChange={handleAcceptTermsChange}
            />
            Acepto los términos y condiciones
          </label>
        </div>

        <div className="terms-links">
          <a href="https://drive.google.com/file/d/1xgzD0NniJmMeO9NEfDdQAwfYpWTzbTQU/view" target="_blank" rel="noopener noreferrer">
            Términos y Condiciones de WOT
          </a>
          <span className="links-separator"></span>
          <a href="https://drive.google.com/file/d/1pa3_57nTHVXqlpa1ozRJRqgiimIRsOwt/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            Política de privacidad de WOT
          </a>
        </div>

        <SubmitButton
          handleLogin={handleSubmit}
          isDataVerified={isDataVerified}
          acceptTerms={acceptTerms}
        />

        {localError && (
          <div className="error-message">
            Debes marcar ambas checkboxes antes de continuar.
          </div>
        )}

      </ThemeProvider>
    </div>
  );
}
