import './styles.css'; // Importa el archivo de estilos CSS
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

import foto from "./imagenes/foto.png";
import imagen1 from "./imagenes/imagen 1.png";
import { useNavigate, useParams } from 'react-router-dom';
import {get_empresa_profile} from '../../api';


function Empresa(id_empresa) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [datos, setDatos] = useState([]);
  const [activeInfo, setActiveInfo] = useState(1);
  const { empresaId } = useParams();
  const [datosEmpresa,setDatosEmpresa] = useState({})
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      navigate("/login");
    }
  }, []);
  
  useEffect(() =>{
    const fetchData = async () =>{
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_empresa_profile(jwtToken,empresaId);
      console.log(response);
      setDatosEmpresa(response);
      setIsLoading(false)
    }
    fetchData();
  }, [])

  const handleInfoChange = (infoNumber) => {
    setActiveInfo(infoNumber);
  };

  
return (
  <div>
    {isLoading ? (
      <p>Loading...</p>
    ) : (
  <><div className='div_profile'>
    {isLoggedIn && (

      <div className="desktop_empresa">
      <div className="div">
        <div className="overlap-group">
        <div className="rectangulo_principal">

          <div className="flex-container">
            <div className="group" ></div>
            <div className="rectangle-fucsia" ></div>
          </div>
          <div className="rectangulo_seccion">
            <p className="nombre-apellido">{datosEmpresa.empresa.nombre}</p>
            <p className="sobre-ti">{datosEmpresa.empresa.valoresEmpresa}</p>
            <p className="sobre-ti">Region {datosEmpresa.empresa.region.nombre}</p>
            <p className="element-contactos">1 mil me gustas - 1.1 mil seguidores</p>
            <div className="flex-container">
              <button className="button_rosa">Seguir</button>
            </div>

         
            <div className="linea_gris"></div>
          </div>
          <div className="rectangle_gris"></div>

          <div className="circulo-blanco" ></div>
          <img className="imagen" alt="Imagen" src={imagen1} />

          <div className="linea_rosa"></div>
          <div className="linea_rosa_corta"></div>
          <div className="elipse-fucsia"></div>

        
          <div className="rectangulo_seccion">          
            <div className="horizontal-bar">
              
              <button onClick={() => handleInfoChange(1)} className={activeInfo === 1 ? "button-activate" : "button-inactivate"}>
                Publicaciones
              </button>

              <button onClick={() => handleInfoChange(2)} className={activeInfo === 2 ? "button-activate" : "button-inactivate"}>
                Seguidores
              </button>

              <button onClick={() => handleInfoChange(3)} className={activeInfo === 3 ? "button-activate" : "button-inactivate"}>
                Menciones
              </button>

              <button onClick={() => handleInfoChange(4)} className={activeInfo === 4 ? "button-activate" : "button-inactivate"}>
                Fotos
              </button>

              <button onClick={() => handleInfoChange(5)} className={activeInfo === 5 ? "button-activate" : "button-inactivate"}>
                Videos
              </button>
            </div>

          </div> 
        </div>


        <div className="rectangulo_abajo_principal">
          {activeInfo === 1 &&
            <div>
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                    <div className='container-empresa'> 
                      <h3 className="nombre-empresa">{datosEmpresa.empresa.nombre}</h3>
                      <p className="fecha">13 de septiembre del 2023</p>
                    </div>
                  <button className='tres-puntos'>...</button>
                </div>
              </div>  
            <img className="foto" alt="Imagen" src={foto} />
            </div>
          }

          {activeInfo === 2 &&
            <div>
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                    <div className='container-empresa'> 
                      <h3 className="nombre-empresa">{datosEmpresa.empresa.nombre}</h3>
                      <p className="fecha">13 de septiembre del 2023</p>
                    </div>
                  <button className='tres-puntos'>...</button>
                </div>
              </div>  
            <img className="foto" alt="Imagen" src={foto} />
            </div>
          }


          {activeInfo === 3 &&
            <div>
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                    <div className='container-empresa'> 
                      <h3 className="nombre-empresa">{datosEmpresa.empresa.nombre}</h3>
                      <p className="fecha">13 de septiembre del 2023</p>
                    </div>
                  <button className='tres-puntos'>...</button>
                </div>
              </div>  
              <img className="foto" alt="Imagen" src={foto} />
            </div>
          }

          {activeInfo === 4 &&
            <div>
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                    <div className='container-empresa'> 
                      <h3 className="nombre-empresa">{datosEmpresa.empresa.nombre}</h3>
                      <p className="fecha">13 de septiembre del 2023</p>
                    </div>
                  <button className='tres-puntos'>...</button>
                </div>
              </div>  
            <img className="foto" alt="Imagen" src={foto} />
            </div>
          }

          {activeInfo === 5 &&
            <div>
              <div className='contenedor-mayor'>
                <div className="flex-container"> 
                  <div className="sphere"></div>
                    <div className='container-empresa'> 
                      <h3 className="nombre-empresa">{datosEmpresa.empresa.nombre}</h3>
                      <p className="fecha">13 de septiembre del 2023</p>
                    </div>
                  <button className='tres-puntos'>...</button>
                </div>
              </div>  
            <img className="foto" alt="Imagen" src={foto} />
            </div>
          }

        </div>

        <div className="rectangulo-arriba">
      
            <p className="informacion">Información</p>
            <p className="datos-empresa"> {datosEmpresa.empresa.valoresEmpresa}</p>

            <p className="element-letra-rosa2">1 mil me gustas - 1.1 mil seguidores</p>
            
            <button className="button-nuevasPublicaciones">
              <p className="button-nuevasPublicaciones-content">Nuevas Publicaciones</p>
              <div className='circulo-rosa'>5</div>
            </button>
            <p className="datos-empresa-oscuro"> Página</p>
            <p className="datos-empresa"> www.empresa.com</p>

        </div>
        
        <div className="segundo-rectangulo">
        
          <div className="flex-container">
            <p className="match">Fotos</p>
            <button className="button_ver_todo">ver todo</button>
          </div> 
          <div className="contenedor-grid">
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
            <img className="grid-item" src={foto} ></img>
          </div> 
        </div> 


      </div>

      </div>
    </div>

    )}
  </div></>
)}
</div>
);
}
export default Empresa;