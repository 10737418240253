import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { ForgotPassword } from './ForgotPassword';
import { SubmitButton } from './SubmitButton';
import { MUItheme } from './MUItheme';

export function FormInput2({ handleInputChange, handleLogin, message, input, input2, buttonText, type, type2 }) {

  return (
    <div className='right-container'>
      <ThemeProvider theme={MUItheme}>
        <h3 className='message-forgot-password'>¿Olvidaste tu contraseña?</h3>
        <h3 className='instructions-forgot-password'>{message}</h3>
        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
        <HiddenInput text={input} onInputChange={handleInputChange} type={type}/>
        <HiddenInput text={input2} onInputChange={handleInputChange} type={type2}/>
        <SubmitButton handleLogin={handleLogin} buttonText={buttonText}/>
      </ThemeProvider>
    </div>
  );
}
