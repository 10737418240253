import React, { useState, useEffect } from 'react';
import '../Login/styles.css';
import { login } from '../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import { FormInput } from './FormInput';
import Cookies from 'js-cookie';
import { LoggingAlert, ErrorAlert } from './Alerts';
import { google_login } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Login() {

  const [formData, setFormData] = useState({
    mail: '',
    contraseña: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  
  const navigate = useNavigate();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');

  useEffect(() => {
    const receiveToken = async (code) => {
      try {
        toast.success('Iniciando sesión con Google...', {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        let response = await google_login(code);
        const responseData = await response;
        if (responseData !== undefined) {
          Cookies.set('jwtToken', responseData.data.access_token, { expires: 1 });
          Cookies.set('userId', responseData.data.id);
          navigate('/perfil');
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (code) {
      receiveToken(code);
    }
  }, [code]);


  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setLoading(true);
    try {
      toast.success('Iniciando sesión...', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      //toast.success('Iniciando sesión...', { autoClose: 1000 });
      let response = await login(formData.mail, formData.contraseña);
      const responseData = await response.json();
      if (response.status === 200) {
        Cookies.set('jwtToken', responseData.access_token, { expires: 1 });
        Cookies.set('userId', responseData.id);
        setLoading(false);
        navigate('/perfil');
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
      toast.error('Los datos ingresados son incorrectos', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  };
 

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      <ToastContainer limit={1}/>
      {/* {loading && <LoggingAlert />}
      {error && <ErrorAlert />} */}
      <div className='main-container-login'>
          <Images />
          <FormInput handleInputChange={handleInputChange} handleLogin={handleLogin} handleRegister={() => navigate('/registro')}/>
      </div>
    </>
  )
  
}

export default Login;