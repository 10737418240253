import { createTheme } from '@mui/material/styles';

export const MUItheme = createTheme({
  palette: {
    primary: {
      main: '#5a5a5a',
    },
    secondary: {
      main: '#9d9d9d',
    },
  }
});