import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function RegisterButton({ handleRegister }) {
  return (
    <Box className='box-user-login'>
      <Button color='secondary' onClick={handleRegister} variant="contained" style={{ fontFamily: 'Montserrat', width: '250px', height: '46.5px', textTransform: 'none', fontSize: '13px', color: 'white' }}>¿Aún no tienes cuenta?</Button>
    </Box>
  );
}
