import React, { useState } from 'react';
import '../Register/styles.css';
import { signup } from '../../api';
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import { FormInputCreate } from './FormInputCreate';
import Cookies from 'js-cookie';
import { LoggingAlert, ErrorAlert } from './Alerts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { responsiveFontSizes } from '@mui/material';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { SubmitButton } from './SubmitButton';

export function CreatePassword() {

  const location = useLocation();
  const nombre = location.state.nombre;
  const apellido = location.state.apellido;
  const mail = location.state.mail;
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(false);
    setErrorMatch(false);
    setErrorFormat(false);
    setLoading(true);
    console.log('hola',formData);
    try {
        if (formData.password != formData.passwordConfirmation){
          setErrorMatch(true);
          toast.error('Las contraseñas no coinciden', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        }
        
        else{
          toast.success('Creando tu cuenta...', {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          let response = await signup(nombre, apellido, mail, formData.password,recaptchaToken);
          const responseData = await response.json();
          if (response.status == 201) {
            Cookies.set('jwtToken', responseData.access_token, { expires: 1 });
            Cookies.set('userId', responseData.id);
            setLoading(false);
            navigate('/perfil');       
          } else{
            console.log("ERROR", responseData.error);
            setError(true);
            toast.error(responseData.error||'Hubo un error al crear tu cuenta', {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
          }
        }
  
      } catch (error) {
        console.log("ERROR", error.message);
        setErrorFormat(true);
        toast.error('Hubo un error al crear tu cuenta', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      } finally {
        setLoading(false);
      }
  };

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value);
  }

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMatch, setErrorMatch] = useState(false);
  const [errorFormat, setErrorFormat] = useState(false);
  return (
    <GoogleReCaptchaProvider reCaptchaKey= {process.env.REACT_APP_CLAVE_SITIO}>
      <ToastContainer />
      <div className='main-container-login'>
        <Images />
        <form onSubmit={handleLogin} className="form-container">
          <FormInputCreate handleInputChange={handleInputChange} />
          <div className="recaptcha-container">
            <GoogleReCaptcha onVerify={handleRecaptcha} action="submit" />
          </div>
          <SubmitButton handleLogin={handleLogin}/>
        </form>
      </div>
    </GoogleReCaptchaProvider>
  );
  
}

export default CreatePassword;