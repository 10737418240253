import React from 'react';
import Alert from '@mui/material/Alert';

export function DeletePublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Publicación eliminada
      </Alert>
    </div>
  );
}
export function ErrorDeletePublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function EditPublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Publicación editada
      </Alert>
    </div>
  );
}
export function ErrorEditPublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function CreateCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Comentario creado
      </Alert>
    </div>
  );
}
export function ErrorCreateCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function DeleteCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Comentario eliminado
      </Alert>
    </div>
  );
}

export function ErrorDeleteCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function RepostAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Publicación reenviada
      </Alert>
    </div>
  );
}

export function JoinComunityAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto', zIndex: 1000 }}>
      <Alert variant="filled" severity="info">
       Uniéndose a comunidad
      </Alert>
    </div>
  );
}

export function ErrorRepostAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Error
      </Alert>
    </div>
  );
}

export function ErrorJoinComunityAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto', zIndex: 1000 }}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function SalirseComunityAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto', zIndex: 1000 }}>
      <Alert variant="filled" severity="info">
       Abandonando comunidad
      </Alert>
    </div>
  );
}

export function ErrorSalirseComunityAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto', zIndex: 1000 }}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}