import React, { useState } from 'react';
import { ThemeProvider } from "@mui/material";
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import { MUItheme } from './MUItheme';
import './styles.css';

export function FormInputCreate({ handleInputChange, handleLogin }) {

  return (
    <div className='clave-container'>
      <ThemeProvider theme={MUItheme}>
        <h3 className='message-login'>Crea tú clave</h3>
        <h3 className='enter-text'>Utiliza al menos  8 caracteres, 1 mayúscula, 1 minúscula, 1 número y un símbolo.</h3>
        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
        <HiddenInput text='Contraseña' value={'password'} onInputChange={handleInputChange}/>
        <HiddenInput text='Repite la contraseña' value={'passwordConfirmation'} onInputChange={handleInputChange}/>
      </ThemeProvider>
    </div>
  );
}
