import React, { useEffect, useState } from 'react';
import './Ajustes.css';


import  {get_notifications_preferences, edit_notifications_preferences} from '../../api.js';
import { ReactComponent as CandadoIcon } from './icons/candado 1.svg';
import Cookies from 'js-cookie';
import {FaBell} from 'react-icons/fa';
import { BiSolidLock } from "react-icons/bi";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';
import PreferenciasLogo from './icons/perfil_logo.png'
import {change_password } from '../../api';
import { EditarContraErrorAlert, EditarContraSuccessAlert} from './Alerts';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

function Ajustes() {
    const [preferencias,setPreferencias] = useState({});
    const [editarContraSuccess, setEditarContraSuccess] = useState(false);
    const [editarContraError , seteditarContraError] = useState(false);

    const [mostrarContrasenaUno, setMostrarContrasenaUno] = useState(false);
    const [mostrarContrasenaDos, setMostrarContrasenaDos] = useState(false);
    const [mostrarContrasenaTres, setMostrarContrasenaTres] = useState(false);

    const toggleMostrarContrasenaUno = () => {
        setMostrarContrasenaUno(!mostrarContrasenaUno);
    };

    const toggleMostrarContrasenaDos = () => {
        setMostrarContrasenaDos(!mostrarContrasenaDos);
    };

    const toggleMostrarContrasenaTres = () => {
        setMostrarContrasenaTres(!mostrarContrasenaTres);
    };

    useEffect(() =>{
        const fetchData = async () =>{
            const token = Cookies.get("jwtToken");
            const preferencias = await get_notifications_preferences(token)
            console.log(preferencias);
            setPreferencias(preferencias.notificationPreferences);
        }
        try{   
           fetchData();
        } catch (error){
            console.error(error)
        }
    },[])
    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase': {
          color: '#5A5A5A',
          transitionDuration: '1000ms', // Pink color for the off state
          '&.Mui-checked': {
            color: '#EE4296', // Pink color for the on state
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#D9D9D9', // Pink color for the track
          },
          '&:not(.Mui-checked) + .MuiSwitch-track': {
            backgroundColor: '#D9D9D9', // Pink color for the track
          },
        },
      }));
      const handlePreference = async (event) =>{
        const token = Cookies.get("jwtToken");
        const name  = event.target.name;
        const preference = event.target.checked;
        const dict_to_send = {[name] : preference};
        setPreferencias(prev => ({
            ...prev,
            [name]: preference
          }));
        try{
            await edit_notifications_preferences(token,dict_to_send);
           
        } catch (error){
            console.error(error);
        }

      }
      const [activeInfo, setActiveInfo] = useState(1);
      const handleInfoChange = (infoNumber) => {
        setActiveInfo(infoNumber);
      };

      
    const changePassword = async (event) => {        
        try {
            event.preventDefault();
            const formData = new FormData(event.target); // Accede a los datos del formulario
            const oldPassword = formData.get("oldPassword");
            const newPassword = formData.get("newPassword");
            const newPasswordConfirmation = formData.get("newPasswordConfirmation");
            if (newPassword == "" || oldPassword == "" || newPasswordConfirmation == ""){
                alert("Debes completar todos los campos.");
            }

            if (newPassword == newPasswordConfirmation){
                const token = Cookies.get('jwtToken');
                let response = await change_password(oldPassword, newPassword, token);
                //const responseData = await response.json();
                //console.log('Response Data:', responseData);
                //console.log(response.status);
                if (response.status == 200){
                    setEditarContraSuccess(true);
                    setTimeout(() => {
                        setEditarContraSuccess(false);
                    }, 2000);
                    //alert('¡Tu contraseña ha sido cambiada con exito!');
                } else {
                    seteditarContraError(true);
                    setTimeout(() => {
                        seteditarContraError(false);
                    }, 2000);
                    //alert('Datos ingresados incorrectos. Intente nuevamente.');
                }
            } else {
                seteditarContraError(true);
                setTimeout(() => {
                    seteditarContraError(false);
                }, 2000);
                //alert('Las contraseñas no coinciden. Intenta nuevamente.');
            }
        
        } catch (error) {
            seteditarContraError(true);
            setTimeout(() => {
                seteditarContraError(false);
            }, 2000);
            //alert('Signup failed. Please try again.');
            console.error(error);
        }
        
    };


    return (
        <div className='fondo_ajustes'>
            <div className='columna_ajustes'>
                <div className='logo_preferencias_notificacion'>
                    <img src={PreferenciasLogo} alt="Logo" className="logo2" />
                </div>
                <div className={activeInfo === 1 ? 'caja_preferencias_activo':'caja_preferencias'} onClick={() => handleInfoChange(1)}>
                    <div className={activeInfo === 1 ? 'barra_lateral_activo':'barra_lateral'}></div>
                    <FaBell className={activeInfo === 1 ? 'campana_preferencias_activo':'campana_preferencias'}></FaBell>
                    <label className='titulo_caja_preferencias'>Preferencias de notificaciones</label>
                    
                </div>
                <div className={activeInfo === 2 ? 'caja_seguridad_activo':'caja_seguridad'} onClick={() =>handleInfoChange(2)}>
                    <div className={activeInfo === 2 ? 'barra_lateral_activo':'barra_lateral'}></div>
                    <BiSolidLock className={activeInfo === 2 ? 'candado_preferencias_activo':'candado_preferencias'}></BiSolidLock>
                    <label className='titulo_caja_seguridad'>Seguridad</label>
                </div>
            </div>
                {activeInfo === 1  ? (
                    <>
                    <div className='cuadro_derecha_ajustes'>
                    <label className='titulo_preferencias_notificaciones'>Notificaciones que recibes</label><div className="notification-option">
                        <span>En búsqueda de empresas</span>
                        <PinkSwitch checked={preferencias.busquedaEmpresas} name='busquedaEmpresas' onChange={event => handlePreference(event)} />
                    </div><div className="notification-option">
                            <span>Conectar con comunidades</span>
                            <PinkSwitch checked={preferencias.conectarComunidades} name='conectarComunidades' onChange={event => handlePreference(event)} />
                        </div><div className="notification-option">
                            <span>Tus publicaciones y comentarios</span>
                            <PinkSwitch checked={preferencias.publicaciones} name='publicaciones' onChange={event => handlePreference(event)} />
                        </div><div className="notification-option">
                            <span>Actividad de comunidades</span>
                            <PinkSwitch checked={preferencias.actividadComunidades} name='actividadComunidades' onChange={event => handlePreference(event)} />
                        </div><div className="notification-option">
                            <span>Actualizaciones de perfil</span>
                            <PinkSwitch checked={preferencias.actualizacionesPerfil} name='actualizacionesPerfil' onChange={event => handlePreference(event)} />
                        </div>
                    </div>
                    </>
                ):null}
                {activeInfo === 2 ?( //type="password"
                    <div className='cuadro_derecha_ajustes_editarcontra'>
                        {editarContraSuccess &&(
                            <EditarContraSuccessAlert/>
                        )}
                        {editarContraError &&(
                            <EditarContraErrorAlert/>
                        )}
                        <label className='titulo_editar_contra'>Editar contraseña</label>
                        <label className='subtitulo_editar_contra'>Usar una mayúscula, mínimo 8 caracteres y un símbolo.</label>
                        <p className='letra-gris-editarcontra'>Para cambiar tu contraseña debes colocar primero tu contraseña actual y luego dos veces la nueva contraseña</p>
                        <form onSubmit={changePassword}>
                            <OutlinedInput
                                id="password1"
                                className='input_editar_contra'
                                name="oldPassword" 
                                placeholder='Contraseña antigua'
                                type={mostrarContrasenaUno ? 'text' : 'password'}
                                endAdornment={<InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleMostrarContrasenaUno}
                                    edge="end"
                                    >
                                    {mostrarContrasenaUno ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>}
                            />

                            <OutlinedInput
                                id="password2"
                                className='input_editar_contra'
                                name="newPassword" 
                                placeholder='Nueva contraseña'
                                type={mostrarContrasenaDos ? 'text' : 'password'}
                                endAdornment={<InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleMostrarContrasenaDos}
                                    edge="end"
                                    >
                                    {mostrarContrasenaDos ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>}
                            />

                            <OutlinedInput
                                id="password3"
                                className='input_editar_contra'
                                name="newPasswordConfirmation" 
                                placeholder='Repetir contraseña'
                                type={mostrarContrasenaTres ? 'text' : 'password'}
                                endAdornment={<InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleMostrarContrasenaTres}
                                    edge="end"
                                    >
                                    {mostrarContrasenaTres ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>}
                            />

                            <button className="button-pink">Cambiar Contraseña</button>
                        </form>
                    </div>
                ):null}
        </div>
    );
}

export default Ajustes