import React from 'react';
import Alert from '@mui/material/Alert';

export function PublicationAlert() {
  return (
    <div className='alert-container'>
      <Alert variant="filled" severity="info">
        Publicación creada
      </Alert>
    </div>
  );
}
export function ErrorPublicationAlert() {
  return (
    <div className='alert-container'>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function DeletePublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Publicación eliminada
      </Alert>
    </div>
  );
}
export function ErrorDeletePublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function EditPublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Publicación editada
      </Alert>
    </div>
  );
}
export function ErrorEditPublicationAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function CreateCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Comentario creado
      </Alert>
    </div>
  );
}
export function ErrorCreateCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}

export function DeleteCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="info">
        Comentario eliminado
      </Alert>
    </div>
  );
}
export function ErrorDeleteCommentAlert() {
  return (
    <div className='alert-container' style={{ position: 'fixed', margin: 'auto'}}>
      <Alert variant="filled" severity="error">
        Error
      </Alert>
    </div>
  );
}