import './styles.css'; // Importa el archivo de estilos CSS
import { get_profile, delete_user, patch_user, change_password } from '../../api';
import { update_cv ,get_paises, get_industrias, get_profesiones, get_universidades, get_cargos, get_anos_experiencia, get_disponibilidades, get_jornadas, get_modalidades, get_idiomas, get_regiones, get_conocio_wot, get_formularios, get_areas, get_competencias, } from '../../api';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import imagen_perfil from "./imagenes/perfil_logo.png"
import wot_logo from "./imagenes/wot_logo.png"


function EditarPerfil() {

    const [nuevosDatos, setNuevosDatos] = useState({});
    const [datosPaises, setDatosPaises] = useState([]);
    const [datosIndustrias, setDatosIndustrias] = useState([]);
    const [datosProfesiones, setDatosProfesiones] = useState([]);
    const [datosAnios, setDatosAnios] = useState([]);
    const [datosUniversidades, setDatosUniversidades] = useState([]);
    const [datosCargos, setDatosCargos] = useState([]);
    const [datosDisponibilidad, setDatosDisponibilidad] = useState([]);
    const [datosJornada, setDatosJornada] = useState([]);
    const [datosModalidades, setDatosModalidades] = useState([]);
    const [datosIdiomas, setDatosIdiomas] = useState([]);
    const [datosCompromiso, setDatosCompromiso] = useState([]);
    const [datosWot, setDatosWot] = useState([]);
    const [datosPersonalidad, setDatosPersonalidad] = useState([]);
    const [datosAreas, setDatosAreas] = useState([]);
    const [datosCompetencia, setDatosCompetencias] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [datos, setDatos] = useState([]);
    const [datosForm,setDatosForm] = useState({})
    const [porcentajeProgress, setPorcentajeProgress] = useState(0);

    const navigate = useNavigate();

    const isFieldEmpty = (value) => {
        console.log("hola", value)
        if(value === '' || value === null || value === undefined){
            return true;
        }
        return false;
      };


    const handleSubmit = async (event) => {
        event.preventDefault();
        // Transformar campos vacíos en null antes de enviarlos a la API
        const datosActualizados = { ...nuevosDatos };
    
        for (const key in datosActualizados) {
          if (datosActualizados[key] === '') {
            datosActualizados[key] = null;
          }
        }
        console.log('Enviando datos actualizados:', datosActualizados);
        
        const token = Cookies.get('jwtToken');
        const responsePatch = await patch_user(token,datosActualizados);
        const response = await get_profile(token);
        const responseData = await response.json();
        if (!("rut" in datosActualizados) && datos.rut === null){
          datosActualizados["rut"] = null;
        }
        else if (!("rut" in datosActualizados) && datos.rut !== null){
          datosActualizados["rut"] = datos.rut;
        }
        //datosActualizados["rut"] = responseData.rut;
        setDatos(responseData);
        setNuevosDatos({});        
        console.log(responsePatch);
        porcentaje(responseData);
        navigate("/perfil");
      };
      const handleContrasena = async (event) => {
        event.preventDefault();
        navigate("/change-password");
      };

    const porcentaje = (datos) =>{
        let count = 0;
        for (let key in datos){
          if (datos[key] === null || (Array.isArray(datos[key]) && datos[key].length === 0)) {
            count++;
            
        }
        console.log(count);
        const progreso = (37-(count-2))/37 * 100;
        console.log(progreso)
        setPorcentajeProgress(progreso)
      }
    }
    const claveMapeo = {
        nombre: 'name',
        apellido: 'lastName',
      };

    const handleInputChange = (event) => {
        try{
        const { name, value } = event.target;
        const nuevaClave = claveMapeo[name] || name;
        setDatos({ ...datos, [name]: value });
        setDatosForm({ ...datosForm, [name]: value });
        setNuevosDatos({ ...nuevosDatos, [nuevaClave]: value });
        console.log(nuevosDatos);
        }
        
        catch (error) {
          console.error('Error:', error);
        }
      };

      const handleDropdownChange = (event) => {
        const { name, value } = event.target;
        setDatos({ ...datos, [name]: value });
        setDatosForm({ ...datosForm, [name]: value });
        setNuevosDatos({ ...nuevosDatos, [name]: value });
        console.log(nuevosDatos);
      };

    useEffect(() => {
        const jwtToken = Cookies.get('jwtToken');
        if (jwtToken) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            navigate("/login");
        }
        }, []);

    useEffect(() => {
        const fetchDataPerfil = async () => {
            try {
            await mostrarUser(); 
            } catch (error) {
            console.error('Error fetching data:', error);
            }
        };
        
        fetchDataPerfil();
        
        }, []);

    const mostrarUser = async () => {
        try{
            const jwtToken = Cookies.get('jwtToken');
            const response = await get_profile(jwtToken);
            if (response.status === 401) {
                Cookies.remove('jwtToken');
                navigate("/login");
            };
            const responseData = await response.json();
            if( responseData["paisDomicilio"] !== null){
              responseData["id_pais_domicilio"] = responseData["paisDomicilio"].id;
            }
            setDatos(responseData);
            porcentaje(responseData);
            const responsePaises = await get_paises();
            setDatosPaises(responsePaises.data);
            const responseIndustrias = await get_industrias();
            setDatosIndustrias(responseIndustrias.data);
            const responseProfesiones = await get_profesiones();
            setDatosProfesiones(responseProfesiones.data);
            const responseUniversidades = await get_universidades();
            setDatosUniversidades(responseUniversidades.data);
            const responseCargos = await get_cargos();
            setDatosCargos(responseCargos.data);
            const responseAnios = await get_anos_experiencia();
            setDatosAnios(responseAnios.data);
            const responseDisponibilidad = await get_disponibilidades();
            setDatosDisponibilidad(responseDisponibilidad.data);
            const responseJornada = await get_jornadas();
            setDatosJornada(responseJornada.data);
            const responseModalidades = await get_modalidades();
            setDatosModalidades(responseModalidades.data);
            const responseIdiomas = await get_idiomas();
            setDatosIdiomas(responseIdiomas.data);
            const responseCompromiso = await get_regiones();
            console.log(responseCompromiso.data);
            setDatosCompromiso(responseCompromiso.data);
            const responseWot = await get_conocio_wot();
            setDatosWot(responseWot.data);
            const responsePersonalidad = await get_formularios();
            setDatosPersonalidad(responsePersonalidad.data);
            const responseAreas = await get_areas();
            setDatosAreas(responseAreas.data);
            const responseCompetencias = await get_competencias();
            setDatosCompetencias(responseCompetencias.data);
            
            console.log(responseData);
            const responseNuevo = await get_profile(jwtToken);
            const responseDataNuevo = await responseNuevo.json();
            setDatos(responseDataNuevo);
            
            const patch = {
            "declaracion": true
            };
            await patch_user(jwtToken,patch);
        } catch (error) {
            console.error('Error:', error);
        }
        };


return (
    <div className='exterior'>
        {isLoggedIn && (
        <div className='flex-edit-container'>
        <form onSubmit={handleSubmit} className='formulario-editar'>
            <label className='editar-info-personal'>Editar información personal</label>
            <>
        
            <div className='form-group'>
                <label className="letra-editar-negrita">Nombre <label className='asterisco-rosa-editar'>*</label> </label>
                <label className='letra-editar-gris'>Ej: Josefina</label>
                <input
                type="text"
                id="name"
                name="nombre"
                value={datos.nombre || ''}
                onChange={handleInputChange}
                className= {`input-editar ${isFieldEmpty(datos.nombre) ? 'campo-no-respondido' : ''}`} />
            </div>  
            <div className='form-group'>
                <label htmlFor="apellido" className="letra-editar-negrita">Apellido <label className='asterisco-rosa-editar'>*</label> </label>
                <label className='letra-editar-gris'>Ej: Lagos</label>
                <input
                    type="text"
                    id="apellido"
                    name="apellido"
                    value={datos.apellido || ''}
                    onChange={handleInputChange}
                    className={`input-editar ${isFieldEmpty(datos.apellido) ? 'campo-no-respondido' : ''}`} />
            </div>
            <div className='form-group'>
                <label htmlFor="rut" className="letra-editar-negrita">Rut <label className='asterisco-rosa-editar'>*</label> </label>
                <label className='letra-editar-gris'>Ej: 23245245-K</label>
                <input
                    type="text"
                    id="rut"
                    name="rut"
                    value={datos.rut || ''}
                    onChange={handleInputChange}
                    className={`input-editar ${isFieldEmpty(datos.rut) ? 'campo-no-respondido' : ''}`} />
                </div>
    
                <div className='form-group'>
                <label htmlFor="celular" className="letra-editar-negrita">Celular <label className='asterisco-rosa-editar'>*</label></label>
                <label className='letra-editar-gris'>Ej: +569 23423456</label>
                <input
                    type="tel"
                    id="celular"
                    name="celular"
                    value={datos.celular || ''}
                    onChange={handleInputChange}
                    className={`input-editar ${isFieldEmpty(datos.celular) ? 'campo-no-respondido' : ''}`} />
                </div><div className='form-group'>
                <label className='letra-editar-negrita'>País de domicilio <label className='asterisco-rosa-editar'>*</label></label>
                <select
                    value={datos.id_pais_domicilio || ''}
                    className={`input-editar ${isFieldEmpty(datos.id_pais_domicilio) ? 'campo-no-respondido' : ''}`}
                    name='id_pais_domicilio'
                    onChange={(event) => handleDropdownChange(event)}>
                    <option value=''>Escoga una opción</option>
                    {datosPaises.map(country => (
                    <option key={country.id} value={country.id}>
                        {country.pais}
                    </option>
                    ))}
                </select>
                </div>

                <div className='form-group'>
                <label htmlFor="Intereses" className="letra-editar-negrita">Intereses <label className='asterisco-rosa-editar'>*</label> </label>
                <label className='letra-editar-gris'>Señala tus principales intereses personales o profesionales.</label>
                <label className='letra-editar-gris'>Máximo 3 palabras separadas por coma.</label>
                <input
                    type="text"
                    id="Intereses"
                    name="intereses"
                    value={datos.intereses || ''}
                    onChange={(event) => handleInputChange(event)}
                    className={`input-editar ${isFieldEmpty(datos.intereses) ? 'campo-no-respondido' : 'input-editar'}`} />
                </div>
                </>
            <div className='botones'>
            <button type="button" onClick={handleContrasena} className='contrasena-edit-button'>Cambiar Contraseña</button>
                <button type="button" onClick={handleSubmit} className='volver-edit-button'>Volver</button>
                <button type="button" onClick={handleSubmit} className='guardar-edit-button'>Guardar</button>
            </div>
        </form>
        <div className='rectangulo-blancoo'>
            <p className='editar-info-match'>Match</p>
            <div className='flex-edit-container'>
                <div className='edit_profile_circle'>
                    <img src={imagen_perfil} className='edit_profile_img_1'/>
                </div>
                <label className='suma-rosa-editar'>+</label>
                <img src={wot_logo} className='wot_logo_edit'/>
            </div>
            <p></p>
            <label className='letra-claudia-gris'>{datos.nombre}, explora oportunidades relevantes con WOT</label>
            <p></p>
            {/* <button className='button-claudia-gris'>Ver perfil</button> */}
            

        </div>
        </div>
        )}
    </div>     
);
}
        
      
export default EditarPerfil;
