import React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export function NormalInput({ text, value, onInputChange }) {
  return (
    <Box className='box-user-login'>
      <FormControl variant="outlined" sx={{ width: '100%', maxWidth: '400px', backgroundColor: '#F5F5F5' }}>
        <InputLabel htmlFor={value} sx={{ fontFamily: 'Montserrat', color: '#9D9D9D' }}>{text}</InputLabel>
        <OutlinedInput
          style={{ fontFamily: 'Montserrat' }}
          id={value}
          label={text}
          onChange={onInputChange}/>
      </FormControl>
    </Box>
  );
}
