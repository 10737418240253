import React, { useRef, useState, useEffect } from 'react';
import './Empresa.css';
import {get_empresa_nombre, get_user_communities} from '../../api';
import Cookies from 'js-cookie';
import CompanyLogo from './logo_empresa.svg'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { useNavigate } from 'react-router-dom';

function EmpresaView() {
    const [searchResults, setSearchResults] = useState([]);
    const inputRef = useRef(null);

    const followedEmpresas = [ // Datos Hardcodeados
    { string1: "Dryhood", string2: "Empresa de artículos de playa" },
    { string1: "Dryhood", string2: "Empresa de artículos de playa" },
    { string1: "Dryhood", string2: "Empresa de artículos de playa" },
    { string1: "Dryhood", string2: "Empresa de artículos de playa" },
    { string1: "Dryhood", string2: "Empresa de artículos de playa" }
  ];


    const irEmpresa = (empresaId) => {
      navigate(`/empresa/${empresaId}`);
    }
    
    const handleSearch = async () => {
        const inputValue = inputRef.current.value;
        const bodyRequest = {nombre: inputValue};
        const token = Cookies.get('jwtToken');
        const response = await get_empresa_nombre(token,bodyRequest);
        setSearchResults(response);
    }

    const [completedForm,setCompletedForm] = useState(true);
    const navigate = useNavigate();

    const checkFormulario = async () =>{
      try {
      const token = Cookies.get('jwtToken');
          const temporal = await get_user_communities(token);
      } catch (error){
        if (error.response && error.response.status === 401) {
          setCompletedForm(false);
          toast.error('Completa tu formulario para acceder a empresas, serás redireccionada a tu perfil', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTimeout(function () {
            console.log('Pasaron 3 segundos');
            navigate("/perfil");
          }, 6000);
        }
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        try {
          await checkFormulario();
        } catch (error) {
          if (error.response && error.response.status === 401) {
            setCompletedForm(false);
            toast.error('Completa tu formulario para acceder a empresas, serás redireccionada a tu perfil', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setTimeout(function () {
              console.log('Pasaron 3 segundos');
              navigate("/perfil");
            }, 6000);
          }
        }
      };
    
      fetchData();
    }, []);

  return (
    <><ToastContainer limit={1} />
    {completedForm ? (
    <div className="empresa-container">  
      <div className="left-column">
        <div className="search-bar">
          <input ref={inputRef} type="text" placeholder="Buscar empresa" className='input-empresa' />
          <button className='boton-buscar' onClick={handleSearch}>Buscar</button>
        </div>
        <div className="followed-empresas-list">
          <h2 className="following-title">Empresas que sigues</h2>
          {followedEmpresas.map((empresa, index) => (
            <div key={index} className="empresa-item">
              <div className='svg-container'>
                <img src={CompanyLogo} alt="Logo" className="empresa-logo" />
              </div>
              <div>
                <a href='#' className="empresa-name">{empresa.string1}</a>
                <p className="empresa-details">{empresa.string2}</p>
              </div>
              <button className="unsubscribe-button">✔ Siguiendo</button>
            </div>
          ))}
        </div>
      </div>
      <div className="right-column">
        <h2>Empresas</h2>
        {searchResults.length > 0 ? (
          searchResults.map((empresa, index) => (
            <div key={index} className="empresa-item">
              <div className='svg-container'>
                <img src={CompanyLogo} alt="Logo Empresa" className="empresa-logo" />
              </div>
              <div>
                <a onClick={() => irEmpresa(empresa.id)} className="empresa-name">{empresa.nombre}</a>
                <div className="empresa-details">{empresa.valoresEmpresa}</div>
              </div>
              <button className='button-seguir-empresa'>Seguir</button>
            </div>
          ))
        ) : (
          <div className="no-results">
            No se han encontrado resultados
          </div>
        )}
      </div>
    </div>):null}
    </>
  );
}

export default EmpresaView;