import React from 'react';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../Register/styles.css';
import { styled } from "@mui/material/styles";

const CustomButton = styled(Button)(() => ({
  fontFamily: 'Montserrat',
  width: '110px',
  height: '40px',
  borderRadius: '7px',
  fontSize: '12px',
  textAlign: 'center',
  textTransform: 'none',
  boxShadow: '0px 2px 2px 0px #00000040',
  margin: '10px',
  marginTop: '0px',
}));

export function LinkedinLogin() {
  return (
    <CustomButton variant="outlined" startIcon={<LinkedInIcon style={{ height: '15px' }} />}
      className='buttonGoogle'>
      LinkedIn
    </CustomButton>
  );
}
