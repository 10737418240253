import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export function HiddenInput({ text, onInputChange, type }) {

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box className='box-user-login'>
      <FormControl variant="outlined" sx={{width: '100%', maxWidth: '400px', backgroundColor: '#F5F5F5' }}>
        <InputLabel htmlFor={type} sx={{ fontFamily: 'Montserrat', color: '#9D9D9D' }}>{text}</InputLabel>
        <OutlinedInput
          id={type}
          style={{ fontFamily: 'Montserrat' }}
          onChange={onInputChange}
          type={showPassword ? 'text' : 'password'}
          endAdornment={<InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>}
          label={text} />
      </FormControl>
    </Box>
  );
}
