import React, { useState, useEffect } from 'react';
import '../Login/styles.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Images } from './Images';
import { FormInput } from './FormInput';
import Cookies from 'js-cookie';
import { google_login } from '../../api';

export function LoginLoading() {
  
  const navigate = useNavigate();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');

  useEffect(() => {
    const receiveToken = async (code) => {
      try {
        let response = await google_login(code);
        const responseData = await response;
        if (responseData !== undefined) {
          Cookies.set('jwtToken', responseData.data.access_token, { expires: 1 });
          Cookies.set('userId', responseData.data.id);
          navigate('/perfil');
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (code) {
      receiveToken(code);
    }
  }, [code]);

  return (
    <>
      <div className='main-container-login-loading'>
        <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
        <img src="/images/logo.svg" alt='logo-loading' className='logo-login' style={{marginTop: '200px'}}/>
        <h3 className='message-login-loading'>Ingresando con Google</h3>
      </div>
    </>
  )
  
}

export default LoginLoading;