import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Notificaciones.css';
import  {get_notifications} from '../../api.js';


import Cookies from 'js-cookie';

import NotificationLogo from './icons/logo_empresa.svg'


function Notificaciones() {
    const [notificaciones,setNotificaciones] = useState([]);
    const [activeInfo, setActiveInfo] = useState(1);
    const navigate = useNavigate();

    const handleInfoChange = (infoNumber) => {
        setActiveInfo(infoNumber);
      };
      function transformDateObjects(dateObjects) {
        return dateObjects.map(obj => {
          const now = new Date();
          const utcDate = new Date(obj.createdAt);
          const diffInSeconds = Math.floor((now - utcDate) / 1000);
          const diffInMinutes = Math.floor(diffInSeconds / 60);
          const diffInHours = Math.floor(diffInSeconds / 3600);
          const diffInDays = Math.floor(diffInHours / 24);
      
          let timeString;
          if (diffInMinutes < 60){
            timeString = `${diffInMinutes} minuto(s)`;
          } else if (diffInHours < 24) {
            timeString = `${diffInHours} hora(s)`;
          } else if (diffInDays < 30) {
            timeString = `${diffInDays} día(s)`;
          } else {
            timeString = `más de 30 días`;
          }
          console.log(timeString);
          return {
            ...obj,
            createdAt: timeString
          };
        });
      }
    useEffect(()=>{
        const token = Cookies.get('jwtToken');
        const fetchData = async () =>{
            const response = await get_notifications(token);
            console.log(response);
            const new_response = transformDateObjects(response.notifications);
            setNotificaciones(new_response);
        }
        try{
          fetchData();
          
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      },[])


    return (
        
        <div className='fondo'>
            <div className='opciones_notificaciones'>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
                    <button onClick={() => handleInfoChange(1)} className={activeInfo === 1 ? "boton_notificacion_activo" : "boton_notificacion_inactivo"}>
                    Todo
                  </button>

                  <button onClick={() => handleInfoChange(2)} className={activeInfo === 2 ? "boton_notificacion_activo" : "boton_notificacion_inactivo"}>
                    Mis publicaciones
                  </button>

                  <button onClick={() => handleInfoChange(3)} className={activeInfo === 3 ? "boton_notificacion_activo" : "boton_notificacion_inactivo"}>
                    Menciones
                  </button>
            </div>

            <div className='cuerpo_notificaciones'>
                {notificaciones ? (
                    <>
                    {notificaciones.map((notificacion) =>(
                    <div className={notificacion.seen === true ? 'container_notificacion_leida': 'container_notificacion'} onClick={() => notificacion.postId && navigate(`/publicacion/${notificacion.postId}`)}>
                        <div className='logo_notificacion'>
                            <img src={NotificationLogo} alt="Logo" className="logo1" />
                        </div>
                        <label className='notificacion'>{notificacion.content}</label>
                        <div className='columna_notificacion'>
                            <label className='hora'>{notificacion.createdAt}</label>
                            <button className='boton_3_puntos'  >
                                <p className='puntos'>...</p>
                            </button>
                        </div>
                    </div>
                ))}</>
                ): <p>Loading...</p>}
            </div>

        </div>
    );
}

export default Notificaciones