import React from 'react';
import Box from '@mui/material/Box';

function LineSeparator() {
    return (
        <div className='container'>
            <Box className='line-separator-box' />
        </div>
    );
}

export default LineSeparator;
