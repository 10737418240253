import React from 'react';

export function ImagesLogin() {
  return (
    <div className='left-container-login'>
      <img src="/images/logo.svg" alt='Logo-login' className="logo-login" />
      <img src='/images/image_inicio.png' alt='Imagen-login' className='image-login' />
      <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
      <h3 className='message-login'>Conectando talento</h3>
    </div>
  );
}
