import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import Login from './components/Login/Login';
import LoginLoading from './components/Login/LoginLoading';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import EnterVerificationCode from './components/ForgotPassword/EnterVerificationCode';
import Register from './components/Register/Register';
import Perfil from './components/Perfil/Perfil';
import Comunidad from './components/Comunidad/Comunidad';
import Comunidades from './components/Comunidades/Comunidades';
import ComunidadesAntiguo from './components/ComunidadesAntiguo/ComunidadesAntiguo';
import Notificaciones from './components/NotificacionesFinal/Notificaciones'
import Usuarias from './components/Usuarias/Usuarias';
import Notifications from './components/Notifications/Notifications';
import CreatePassword from './components/Register/CreatePassword';
import Ajustes from './components/Ajustes/Ajustes';
import Empresa from './components/Empresa/Empresa';
import Feed from './components/Feed/Feed';
import EmpresaView from './components/BusquedaEmpresas/Empresa';
import ChangePassword from './components/Perfil/ChangePassword';

import Publicacion from './components/Publicacion/Publicacion';

import EditarPerfil from './components/EditarPerfil/EditarPerfil';

import { FaAppStore } from "react-icons/fa";
import { FaGooglePlay } from "react-icons/fa";




function Home() {
  return( <div className='App'>
            <Header />
            <LandingPage />
          </div>
  );
}

function Reg() {
  return( <div className='App'>
            <Register />
          </div>
  );
}

function Log() {
  return( <div className='App'>
            <Login />
          </div>
  );
}

function LoginGoogle() {
  return( <div className='App'>
            <LoginLoading />
          </div>
  );
}

function Pf() {
  return( <div className='App'>
            <Header />
            <Perfil />
          </div>
  );
}

function Com() {
  return( <div className='App'>
            <Header />
            <Comunidades />
          </div>
  );
}

function IndCom() {
  return( <div className='App'>
            <Header />
            <Comunidad />
          </div>
  );
}


function ComAntiguo() {
  return( <div className='App'>
            <Header />
            <ComunidadesAntiguo />
          </div>
  );
}

function Users() {
  return( <div className='App'>
            <Header />
            <Usuarias />
          </div>
  );
}


function Noti() {
  return( <div className='App'>
            <Header />
            <Notifications />
          </div>
  );
}

function Empre() {
  return( <div className='App'>
            <Header />
            <Empresa />
          </div>
  );
}

function Fee() {
  return( <div className='App'>
            <Header />
            <Feed />
          </div>
  );
}

function BusEm() {
  return( <div className='App'>
            <Header />
            <EmpresaView/>
          </div>
  );
}

function IndPub() {
  return( <div className='App'>
            <Header />
            <Publicacion />
          </div>
  );
}

function EditProf() {
  return( <div className='App'>
            <Header />
            <EditarPerfil />
          </div>
  );
}

function Notis(){
  return( <div className='App'>
            <Header />
            <Notificaciones />
          </div>
  );
}
function Ad(){
  return( <div className='App'>
            <Header />
            <Ajustes />
          </div>
  );
}

function CP() {
  return( <div className='App'>
            <Header />
            <ChangePassword />
          </div>
  );
}

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = () => {
    // if (screenWidth < 768) {
    //   return (
    //     <>
    //       <div className='row app_logos_div'>
    //         <div className='col logo_column'>
    //           <div className='row'>
    //             <FaAppStore size={80} style={{margin: 'auto'}}/>
    //           </div>
    //           <div className='row'>
    //             <h6 className='type_app_store'>App store</h6>
    //           </div>
    //         </div>
    //         <div className='col logo_column'>
    //           <div className='row'>
    //             <FaGooglePlay size={80} style={{margin: 'auto'}}/>
    //           </div>
    //           <div className='row'>
    //             <h6 className='type_app_store'>Google play</h6>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='row small_screen_message_title_div'>
    //         <h1 className='small_screen_message_title'>¡Descarga nuestra app!</h1>
    //       </div>
    //     </>
    //   );
    // } 
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Log />} />
          <Route path="/googlelogin" element={<LoginGoogle />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/enterverificationcode" element={<EnterVerificationCode />} />
          <Route path="/registro" element={<Reg />} />
          <Route path="/createpassword" element={<CreatePassword />} />
          <Route path="/perfil" element={<Pf />} />
          <Route path="/comunidades" element={<Com />} />
          <Route path="/comunidades_antiguo" element={<ComAntiguo />} />
          <Route path="/usuarias" element={<Users />} />
          {/*<Route path="/notificaciones" element={<Noti />} />*/}
          <Route path="/comunidad/:communityId" element={<IndCom />} />
          <Route path="/empresa/:empresaId" element={<Empre />} />
          <Route path="/feed" element={<Fee />} />
          <Route path="/empresa_busqueda" element={<BusEm />} />
          <Route path="/publicacion/:publicationId" element={<IndPub />} />
          <Route path="/editar_perfil" element={<EditProf />} />
          <Route path="/notificaciones" element={<Notis />} />
          <Route path="/ajustes" element={<Ad />} />
          <Route path="/change-password" element={<CP />} />
        </Routes>
      </Router>
    );
  };
  return (
    <>{renderContent()}</>
  );
}

export default App;