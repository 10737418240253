import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function SubmitButton({ handleLogin }) {
  return (
    <Box className='box-user-login'>
      <Button onClick={handleLogin} variant="contained" className="login_pink_button">Entrar</Button>
    </Box>
  );
}
