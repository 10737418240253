// Footer.js
import React, { useEffect, useState } from 'react';
import './styles.css';
import { useForm } from "react-hook-form";
import Cookies from 'js-cookie';
import { get_communities, get_user_communities, get_profile,
  get_community_publications, delete_publication, like_publication,
  edit_publication, create_comment, delete_comment, get_comments, 
  get_publication} from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import { PublicationAlert, ErrorPublicationAlert } from './Alerts';
import { DeletePublicationAlert, ErrorDeletePublicationAlert, 
  EditPublicationAlert, ErrorEditPublicationAlert, CreateCommentAlert, ErrorCreateCommentAlert,
DeleteCommentAlert, ErrorDeleteCommentAlert} from './Alerts';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { useParams } from 'react-router-dom';

import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import {AiFillHeart} from 'react-icons/ai';
import {MdDone} from 'react-icons/md';
import {BiPencil} from 'react-icons/bi';

import imagen_perfil from "./imagenes/perfil_logo.png"




function Publicacion() {
  const { publicationId } = useParams();
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMember, setIsMember] = useState(false);

  const [community, setCommunity] = useState([]);

  const [currentUser, setCurrentUser] = useState({});
  const [post, setPost] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [publicationImages, setImages] = useState([]);

  const [isEditFormVisible, setIsEditFormVisible] = useState({});
  const {register, handleSubmit } = useForm();

  const [comments, setComments] = useState([]);
  const [isCommentsVisible, setIsCommentsVisible] = useState({});
  const [createComment, setCreateComment] = useState('');

  const [publicationSuccess, setPublicationSuccess] = useState(false);
  const [publicationError, setPublicationError] = useState(false);
  const [deletePublicationSuccess, setDeletePublicationSuccess] = useState(false);
  const [publicationDeleteError, setDeletePublicationError] = useState(false);

  const [editPublicationSuccess, setEditPublicationSuccess] = useState(false);
  const [editPublicationError, setEditPublicationError] = useState(false);

  const [createCommentSuccess, setCreateCommentSuccess] = useState(false);
  const [createCommentError, setCreateCommentError] = useState(false);

  const [deleteCommentSuccess, setDeleteCommentSuccess] = useState(false);
  const [commentDeleteError, setDeleteCommentError] = useState(false);


  const fetchIsMember = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await get_user_communities(token);
      const responseData = await response.data;
      for (let own_community of responseData.otherCommunities){
        if (own_community.id == community.id){
          setIsMember(true);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const fetchCurrentUser = async () => {
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await get_profile(jwtToken);
      const responseData = await response.json();
      setCurrentUser(responseData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchPublication();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
    try{
      await fetchCurrentUser();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   }
    fetchData();
  }, []);


  useEffect(() => {
    // Check if the user is a member when `community` is updated
    if (community) {
      fetchIsMember();
    }
  }, [community]);


  const handleLikePost = async (postId) => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await like_publication(token, postId);
      await fetchPublication();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchPublication = async () => {
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const allPosts = [];
      const allCargos = [];
      const allImages = {};
      const response = await get_publication(token, publicationId);
      const responseData = await response.data;
      console.log(responseData.post.cargo);
      setPost(responseData.post);
      setCargo(responseData.post.cargo.cargo);
      setImages(responseData.post.imageLinks);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const editPublicationSubmit = async (data, publicationId) => {
    try {
      const content = data[`publication-${publicationId}`];
      const token = Cookies.get('jwtToken');
      let response = await edit_publication(token, publicationId, content);
      if (response.status === 200) {
        fetchPublication();
        handleShowPublicationEditForm(publicationId);
        setEditPublicationError(false);
        setEditPublicationSuccess(true);
        setTimeout(() => {
          setEditPublicationSuccess(false);
        }, 2000);
      }
    } catch (error) {
      setEditPublicationError(true);
      setEditPublicationError(false);
      setTimeout(() => {
        setEditPublicationError(false);
      }, 2000);
    }
  };

  const handleShowPublicationEditForm = async (publicationId) => {
    if (isEditFormVisible[publicationId]){
      toggleEditPublicationVisibility(publicationId);
    } else {
      toggleEditPublicationVisibility(publicationId);
    }
  };

  const toggleEditPublicationVisibility = (publicationId) => {
    setIsEditFormVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };

  const handleDeletePublication = async (publicationId) => {
    const confirmed = window.confirm("¿Estas segura de que quieres eliminar la publicación?");
    if (confirmed) {
      try {
        const token = Cookies.get('jwtToken');
        let response = await delete_publication(token, publicationId);
        if (response.status === 200) {
          fetchPublication();
          setDeletePublicationError(false);
          setDeletePublicationSuccess(true);
          setTimeout(() => {
            setDeletePublicationSuccess(false);
          }, 2000);
        }
      } catch (error) {
        setDeletePublicationError(true);
        setDeletePublicationSuccess(false);
        setTimeout(() => {
          setDeletePublicationError(false);
        }, 2000);
      }
    }
  };

  const fetchComments = async () => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await get_comments(token, publicationId);
      const comments = await response.data.comments;
      setComments(comments);  
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchComments();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData(); // Call the async function immediately inside useEffect
  
  }, [post]);

  const handleCreateComment = async (e, postId) => {
    e.preventDefault();
    try {
      // Make a GET request to your API endpoint
      const token = Cookies.get('jwtToken');
      const response = await create_comment(token, postId, createComment);
      if (response.status === 201) {
        await fetchComments();
        setCreateCommentError(false);
        setCreateCommentSuccess(true);
        setTimeout(() => {
          setCreateCommentSuccess(false);
        }, 2000);
        isCommentsVisible[postId] = true;
      } else {
        setCreateCommentError(true);
        setCreateCommentSuccess(false);
        setTimeout(() => {
          setCreateCommentError(false);
        }, 2000);  
      }
      await fetchComments();
    } catch (error) {
      setCreateCommentError(true);
      setCreateCommentSuccess(false);
      setTimeout(() => {
        setCreateCommentError(false);
      }, 2000);  
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const token = Cookies.get('jwtToken');
      const response = await delete_comment(token, commentId);
      if (response.status === 204) {
        setDeleteCommentError(false);
        setDeleteCommentSuccess(true);
        setTimeout(() => {
          setDeleteCommentSuccess(false);
        }, 2000);
        await fetchComments();
      } else {
        setDeleteCommentError(true);
        setDeleteCommentSuccess(false);
        setTimeout(() => {
          setDeleteCommentError(false);
        }, 2000);  
      }
    } catch (error) {
      setDeleteCommentError(true);
      setDeleteCommentSuccess(false);
      setTimeout(() => {
        setDeleteCommentError(false);
      }, 2000);  
    }
  };

  const handleCollapseComments = async (publicationId) => {
    await fetchComments();
    if (isCommentsVisible[publicationId]){
      toggleCommentsVisibility(publicationId);
    } else {
      toggleCommentsVisibility(publicationId);
    }
  };

  const toggleCommentsVisibility = (publicationId) => {
    setIsCommentsVisible((prevVisibility) => ({
      ...prevVisibility,
      [publicationId]: !prevVisibility[publicationId], // Toggle visibility
    }));
  };


  return (
    <>
    {isLoggedIn && (
      <div className="div single_publication_div">
        {publicationSuccess &&( 
          <PublicationAlert />
        )}
        {publicationError &&(
          <ErrorPublicationAlert />
        )}
        <div>
          <div>

        
          <div>
            {deletePublicationSuccess &&( 
              <DeletePublicationAlert />
            )}
            {publicationDeleteError &&(
              <ErrorDeletePublicationAlert />
            )}
            {editPublicationSuccess &&( 
              <EditPublicationAlert />
            )}
            {editPublicationError &&(
              <ErrorEditPublicationAlert />
            )}
            {createCommentSuccess &&( 
              <CreateCommentAlert />
            )}
            {createCommentError &&(
              <ErrorCreateCommentAlert />
            )}
            {deleteCommentSuccess &&( 
              <DeleteCommentAlert />
            )}
            {commentDeleteError &&(
              <ErrorDeleteCommentAlert />
            )}
            <div>
                <div className='publication_text_div'>
                    {currentUser.id == post.userId ?(
                    <div className='row community_publication_options_div'>
                        <div className='col single_publication_edit_option'>
                            <BiPencil className='publication_edit_button' size={21} onClick={() => handleShowPublicationEditForm(post.id)}/>
                        </div>
                    </div>
                    ):(
                    <></>
                    )}
                    <div className='row'>
                    <div className='col publication_text_div'>
                        <div className='row'>
                        <div className='col publication_user_image'>
                            <div className='comment_profile_circle'>
                            <img src={imagen_perfil} className='comment_profile_img_1'/>
                            </div>
                        </div>
                        <div className='col publication_user_text'>
                            <h4 className='community_cargo_publication_text'>{cargo}</h4>
                            <h4 className='community_date_text'>{post.updatedAt}</h4>
                        </div>
                        </div>
                        {!isEditFormVisible[post.id] && (
                        <div className='single_publication_text_div'>
                            <h3 className='single_publication_text'>
                            {post.content}
                            </h3>
                            {publicationImages?.map((image) => (
                            <img src={image} alt="" className='single_publication_image' />
                            ))}
                        </div>
                        )}
                        {isEditFormVisible[post.id] && (
                        <div className='row'>
                            <div className='col community_publication_edit_col'>
                            <form
                                className='edit_publication_form'
                                onSubmit={(e) => handleSubmit((data) => editPublicationSubmit(data, post.id))(e)}
                            >
                                <div className='row'>
                                <div className='col'>
                                    <textarea
                                    
                                    {...register(`publication-${post.id}`)}
                                    placeholder='Publicación'
                                    style={{ height: '7vh', width: '32vw', borderRadius: '10px' , marginLeft: '5vw'}}
                                    defaultValue={post.content}
                                    />
                                </div>
                                <div className='col'>
                                    <button type='submit' className='community_publication_edit_button'>
                                    <MdDone size={14}/>
                                    </button>
                                </div>
                                </div>
                            </form>
                            </div>
                            <div className='col community_publication_delete_col'>
                            <img src='/images/cancel_icon.svg' className='community_publication_cancel_icon' onClick={() => handleDeletePublication(post.id)}/>
                            </div>
                        </div>
                        )}
                    </div>
                    </div>
                </div>
                <div className='row community_publication_options'>
                    {post.liked &&(
                    <div className='col community_publication_like_col' onClick={() => handleLikePost(post.id)}>
                        <h4 className='community_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='liked_publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                    </div>
                    )}
                    {!post.liked &&(
                    <div className='col community_publication_like_col' onClick={() => handleLikePost(post.id)}>
                        <h4 className='community_publication_options_text' onClick={() => handleLikePost(post.id)}><AiFillHeart className='publication_options_icon' onClick={() => handleLikePost(post.id)}/> Me gusta</h4>
                    </div>
                    )}
                    <button className="community_publication_options_button" onClick={() => handleCollapseComments(post.id)}>
                    <img src="/images/comment_logo.svg" className='publication_options_icon' alt='Comment Icon'/>
                    <span className='publication_options_text'>Comentarios</span>
                    </button>
                </div>
                {isCommentsVisible[post.id] && (
                    <div className='single_publication_comments_div'>
                    {comments.map((comment) => (
                        <div className='comment_div'>
                        <div className='col publication_delete_option'>
                            <img src='/images/cancel_icon.svg' className='publication_comment_cancel_icon' onClick={() => handleDeleteComment(comment.id)}/>
                        </div>
                        <h4 className='comment_text'>{comment.content}</h4>
                        </div>
                    ))}
                    </div>
                )}
                <div className='row community_publication_comment'>
                    <div className='col'>
                    <div>
                        <img src={imagen_perfil} className='comment_profile_img'/>
                        <img src='/images/circle.svg' className='publication_profile_circle'/>
                    </div>
                    </div>
                    <div className='col' key={post.id}>
                    <form className='box comment_form' onSubmit={(e) => handleCreateComment(e, post.id)}>
                        <input
                        className='community_comment_input'
                        placeholder='Escribe algo...'
                        onChange={(e) => setCreateComment(e.target.value)}
                        />
                    </form>
                    </div>
                </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      )}
    </>
  );
  
}

export default Publicacion;
